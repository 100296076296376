import React, {FC, useEffect} from 'react'
import {PageTitle} from '../../design/layout/core'
// import {useIntl} from 'react-intl'
import {useParams} from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'
import {ApplicationState} from '../../../store'
import {ComponentState} from '../../../store/ducks/component/types'
import Loading from '../../design/loading'

import {PageWidget} from './PageWidget'
import { loadComponentRequest } from '../../../store/ducks/component/actions'
import { VideoWidget } from './VideoWidget'
import { ImgWidget } from './ImgWidget'

type ParamTypes = {
  id: string
}

// const MOMENT= require( 'moment' );

type Props = {
  comp: ComponentState
  id: string
}
const PagesPage: React.FC<React.PropsWithChildren<Props>> = ({comp, id}) => (
  <>
    {/* begin::Row */}
    <div className='row g-5 gx-xxl-12'>
      
        {comp.data.children?.map(item => {
          return (
            <div className='col-xxl-4'>
              <VideoWidget 
                className='card-xl-stretch mb-xl-8'
                chartColor='blue'
                name={item.name!}
                extras={item.extras!}
                height={44}
                id={item.id!}
                description={item.description!}
              />
            </div>
          )
        })}
        
      
    </div>
    {/* end::Row */}
  </>
)

type PropsId = {
  id: number
}
const VideoPage: FC<React.PropsWithChildren<PropsId>> = ({id}) => {
  // const intl = useIntl()
  //let {id} = useParams<ParamTypes>() //id do módulo
  const component = useSelector((state: ApplicationState) => state.component)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadComponentRequest(''+id))
  }, [id])
  document.title = component.data.name + ' | Salve mais um';
  console.log("id", id)
  console.log("Component", component)

  if (component.loading) return <Loading />

  // let today = MOMENT().format( 'YYYY-MM-DD HH:mm:ss.000' );

  return (
    <>
      {/* <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.MODULES'})} </PageTitle> */}
      <PageTitle breadcrumbs={[]}>{component.data.name}</PageTitle>
      <PagesPage comp={component} id={''+id} />
    </>
  )
}
export {VideoPage}
