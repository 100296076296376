/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {
  KTSVG,
  // toAbsoluteUrl
} from '../../design/helpers'
// import { ComponentState } from '../../../../store/ducks/component/types'
import {Link} from 'react-router-dom'
import {useIntl} from 'react-intl'
import { ComponentState } from '../../../store/ducks/component/types'
import { CampaignState } from '../../../store/ducks/campaign/types'
import { Bloodcenter, BloodcenterState } from '../../../store/ducks/bloodcenter/types'
import _ from 'lodash'
const MOMENT = require('moment')
require("moment-duration-format");
// const MOMENT= require( 'moment' );

type Props = {
  className: string
  bc: BloodcenterState
}

const SearchWidget: React.FC<React.PropsWithChildren<Props>> = ({className, bc}) => {
  const [separated, setSeparated] = useState<any>([])
  const separaRegioes = (data:Bloodcenter[]) => {
    let separated: any;

    separated = _.groupBy(data, (d:any) => d.region)
    //Voltar o 3/4 para o lugar
    // console.log("primeiro", separated)
    separated = _.reduce(
      separated,
      (acc:any, next:any, index:any) => {

        acc.push({
          title: index,
          data: next,
        });
        return acc;
      },
      [],
    );

    let selection: any[] = []
    separated.map((selectOptions:any) => {
      selection.push({id: 0, name : selectOptions.title, disabled: true})
      selectOptions.data.map((item: any) => {
        selection.push(item)
        //console.log("itemxx", item)
      })
      
    })
    console.log("SEPARATED", separated)
    console.log("selection", selection)

    setSeparated(selection)
  }

  let lista = bc.search.length?bc.search:bc.data;
  useEffect(() => {
      separaRegioes(lista)
  }, [lista])
  
  // console.log("MODULOS", comp)
  const intl = useIntl()
  
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>
            {intl.formatMessage({id: 'MENU.BLOODCENTER'})}
          </span>
          <span className='text-muted mt-1 fw-bold fs-7'>
            Clique no link para ser redirecionado
          </span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        <div className='tab-content'>
          {/* begin::Tap pane */}
          <div className='tab-pane fade show active' id='kt_table_widget_5_tab_1'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='border-0'>
                    <th className='p-0 w-150px'></th>
                    <th className='p-0 min-w-150px'></th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {separated?.map((component: any, index: any) => {
                    if(component.disabled) 
                      return <tr style={{ backgroundColor: '#EAEAEA' }}><td className='p-5'>{component.name}</td><td></td></tr>
                    
                    return (
                      <tr>
                        <td>
                        <Link
                            className='fs-6 text-gray-800 text-hover-primary fw-bolder'
                            to={
                              '/hemocentro/' +
                              component.id
                            }
                            style={{display: 'flex'}}
                          >
                          <img 
                            // src={'../../files/' + component.image} 
                            src={'https://salvemaisum.com.br/files/' + component.image} 
                            style={{width: '100%'}} 
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src="https://salvemaisum.com.br/media/guestuser.jpg";
                            }}
                          />
                          </Link>
                        </td>
                        <td>
                          <Link
                            className='fs-6 text-gray-800 text-hover-primary fw-bolder'
                            to={
                              '/hemocentro/' +
                              component.id
                            }
                            style={{display: 'flex'}}
                          >
                            {component.name}
                          </Link>
                          {/* <span className='text-muted fw-bold d-block'>{bloodtypetext(component.bloodtype)}</span>
                          <span className='text-muted fw-bold d-block'>Recebe sangue: {recebeDe(component.bloodtype)}</span> */}
                          <span className='text-muted fw-bold d-block'>{component.description}</span>
                          <span className='text-muted fw-bold d-block'>{component.address}</span>
                          <span className='text-muted fw-bold d-block'>{component.cityParent?.name}/{component.stateParent?.state}</span>
                        </td>
                        
                        {/* <td className='text-end text-muted fw-bold'>{component.parentName}</td> */}
                        {/* component.search.map((component,index) => 
                            <div key={index}>
                              {component.parentName} {component.name} {component.id} {component.parentId} {component.firstId} {component.value_extra}
                            </div>
                        ) */}
                      </tr>
                    )
                  })}
                </tbody>
                {/* end::Table body */}
              </table>
            </div>
            {/* end::Table */}
          </div>
          {/* end::Tap pane */}
        </div>
      </div>
      {/* end::Body */}
    </div>
  )
}

export {SearchWidget}
