/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Extras } from '../../../store/ducks/extras/types'
import { User } from '../../../store/ducks/me/types'
// import ApexCharts, {ApexOptions} from 'apexcharts'
import {
  // KTSVG,
  toAbsoluteUrl,
} from '../../design/helpers'
// import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
// import {Dropdown1} from '../../content/dropdown/Dropdown1'
const MOMENT = require('moment')

// const doaPara = (bloodtype: string) => {
//   switch (bloodtype) {
//     case '1':
//       return "A+, A-, B+, B-, AB+, AB-, O+, O-" //O-
//     case '2':
//       return "A+, B+, AB+, AB-" //O+
//     case '3':
//       return "A+, A-, AB+, AB-" //A-
//     case '4':
//       return "A+, AB+" //A+
//     case '5':
//       return "B+, B-, AB+, AB-" //B-
//     case '6':
//       return "B+, AB+" //B+
//     case '7':
//       return "AB+, AB-" //AB-
//     case '8':
//       return "AB+" //AB+
//     default:
//       return "Invalido"
//   }
// }

const recebeDe = (bloodtype: string) => {
  switch (bloodtype) {
    case '1':
      return "O-" //O-
    case '2':
      return "O+, O-" //O+
    case '3':
      return "A-, O-" //A-
    case '4':
      return "A+, A-, O+, O-" //A+
    case '5':
      return "B-, O-" //B-
    case '6':
      return "B+, B-, O+, O-" //B+
    case '7':
      return "A-, B-, AB-, O-" //AB-
    case '8':
      return "A+, A-, B+, B-, AB+, AB-, O+, O-" //AB+
    default:
      return "Invalido"
  }
}

const bloodtypetext = (bloodtype: string) => {
  switch (bloodtype) {
    case '1':
      return "O-"
    case '2':
      return "O+"
    case '3':
      return "A-"
    case '4':
      return "A+"
    case '5':
      return "B-"
    case '6':
      return "B+"
    case '7':
      return "AB-"
    case '8':
      return "AB+"
    default:
      return "Invalido"
  }
}

type Props = {
  className: string
  chartColor: string
  name: string
  description: string
  height: number
  id: number
  extras: Extras[]
}

const ImgWidget: React.FC<React.PropsWithChildren<Props>> = ({
  className,
  chartColor,
  name,
  height,
  extras,
  description
}) => {
  console.log("extras", extras)
  return (
    <>
      <div className={`toHoverNoPointer card ${className}`}>
        {/* begin::Header */}
        <div
          className={
            `wave border-0 bg-${chartColor}`
          }
          style={{
            //justifyContent: 'center',
            // alignItems: 'flex-end',
            // flex: '1 1 0%',
            display: 'flex',
            backgroundImage: `url(${'../../files/'+extras[0]?.value_extra}), url(../../files/system/bg-campaign.jpg)`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover, cover', //contain | cover
            backgroundPosition: 'top center',
            // color: 'rgba(255, 255, 255, 0.1)',
            height: height,
          }}
        > 
          {/* <h3 className='card-title fw-bolder text-white' style={{height: height}}>{name}</h3> */}

          <div className='card-toolbar'>
            {/* begin::Menu *-/}
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-white btn-active-white btn-active-color- border-0 me-n3'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
          </button>
          <Dropdown1 />
          {/* end::Menu */}
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body p-0'>
          {/* begin::Stats */}
          <div className='card-p position-relative'>
            {/* begin::Row */}
            <div className='row'>
              <div className='col-12'>
                
                <div className='card-title fw-bolder text-dark'>{name}</div>
                <div className='text-dark' dangerouslySetInnerHTML={{__html: description}}></div>
                {/* <div className='card-title fw-bolder text-dark'>Precisa de: </div>
                <div className='text-dark'>teste</div> */}
              </div>
            </div>
            {/* end::Row */}
            

            
            {/* <div className='text-dark'>{bcaddress + ' ' + bcname + ' ' + bccity + ' ' + bcstate }</div> */}
          </div>
          {/* end::Stats */}
        </div>
        {/* end::Body */}
      </div>
    </>
  )
}

export {ImgWidget}
