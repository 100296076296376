import {action} from 'typesafe-actions'
import {ComponentTypes} from './types'
import {Component} from './types'
import {Extras} from '../extras/types'

//Load
export const loadComponentRequest = (id: string) =>
  action(ComponentTypes.LOAD_COMPONENT_REQUEST, id)
export const loadComponentSuccess = (data: Component) =>
  action(ComponentTypes.LOAD_COMPONENT_SUCCESS, data)
export const loadComponentFailure = () => action(ComponentTypes.LOAD_COMPONENT_FAILURE)


//Create Component
export const createComponentRequest = (newComponent: Component) =>
  action(ComponentTypes.CREATE_COMPONENT_REQUEST, newComponent)
export const createComponentSuccess = (data: Component) =>
  action(ComponentTypes.CREATE_COMPONENT_SUCCESS, data)
export const createComponentFailure = (err: any[]) =>
  action(ComponentTypes.CREATE_COMPONENT_FAILURE, err)

//Update Component
export const updateComponentRequest = (componentToUpdate: Component) =>
  action(ComponentTypes.UPDATE_COMPONENT_REQUEST, componentToUpdate)
export const updateComponentSuccess = (data: Component) =>
  action(ComponentTypes.UPDATE_COMPONENT_SUCCESS, data)
export const updateComponentFailure = () => action(ComponentTypes.UPDATE_COMPONENT_FAILURE)

//Delete Component
export const deleteComponentRequest = (id: number) =>
  action(ComponentTypes.DELETE_COMPONENT_REQUEST, id)
export const deleteComponentSuccess = (id: number) =>
  action(ComponentTypes.DELETE_COMPONENT_SUCCESS, id)
export const deleteComponentFailure = () => action(ComponentTypes.DELETE_COMPONENT_FAILURE)

//Search
export const searchRequest = (search: string) => action(ComponentTypes.SEARCH_REQUEST, search)
export const searchSuccess = (data: Component) => action(ComponentTypes.SEARCH_SUCCESS, data)
export const searchFailure = (error: {}) => action(ComponentTypes.SEARCH_FAILURE, error)