import React from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import {Trainning} from "../pages/Trainning"

import Search from '../pages/search'
import { AuthPage } from '../modules/auth'
import ChangePassInternal from '../pages/changepassinternal'
import { Contact } from '../pages/contact/Contact'
import { ViewCampaign } from '../pages/campaign/view/ViewCampaign'
import ManageCampaign from '../pages/campaign/manage/ManageCampaign'
import { ViewProfile } from '../pages/profile/view/ViewProfile'
import { EditProfile } from '../pages/profile/edit/EditProfile'
import { TextPage } from '../pages/textPage/TextPage'
import { VideoPage } from '../pages/componentPage/VideoPage'
import { ImgPage } from '../pages/componentPage/ImgPage'
import Bloodcenters from '../pages/bloodcenters'
import { ViewBloodCenter } from '../pages/bloodcenters/ViewBloodcenter'
import Update from '../pages/campaign/manage/update'
// import ChangePass from '../../app/modules/'

export function PrivateRoutes() {
  console.log("Bateu aqui..")
 
  return (
      <Routes>
        <Route path="/rede" element={<Trainning/>} />
        <Route path="/auth/*" element={<AuthPage/>} />
        {/* <Route path='/initiative' element={<Initiative/>} /> */}
        {/* <Route path='/howitworks' element={<Howitworks/>} /> */}
        {/* <Route path='/whoweare' element={<Whoweare/>} /> */}
        
        {/* <Route path='/howtodonate' element={<Howtodonateblood/>} /> */}
        <Route path='/contact' element={<Contact/>} />

        <Route path='/edit' element={<EditProfile/>} />
        
        <Route path='/hemocentros' element={<Bloodcenters/>} />
        <Route path='/hemocentro/:id' element={<ViewBloodCenter/>} />

        {/* <Route path='/update/:id' element={<Update child={{}} />} /> */}

        <Route path='/palestras' element={<TextPage id={2}/>} />
        <Route path='/namidia' element={<VideoPage id={3}/>} />
        <Route path='/redessociais' element={<TextPage id={4}/>} />
        <Route path='/doar' element={<TextPage id={5}/>} />
        <Route path='/whoweare' element={<ImgPage id={6}/>} />
        <Route path='/howtodonate' element={<TextPage id={7}/>} />
        <Route path='/initiative' element={<TextPage id={8}/>} />
        <Route path='/howitworks' element={<TextPage id={9}/>} />
        {/* <Route path="dynamic">
          <Route path=":id" element={<Pages/>}/>
        </Route> */}

        <Route path="campanha">
          <Route path=":id" element={<ViewCampaign/>}/>
        </Route>

        <Route path="profile">
          <Route path=":id" element={<ViewProfile/>}/>
        </Route>

        <Route path='/campaigns' element={<ManageCampaign/>} />

        <Route path="changePass" element={<ChangePassInternal/>} />

        <Route path="search" element={<Search/>} />

        {/* <Route path ="/auth/login" element={<Navigate to="/"/>} /> */}
        <Route path="index.html" element={<Navigate to="/rede"/>} />
        <Route path="/index.html" element={<Navigate to="/rede"/>} />
        <Route index element={<Navigate to="/rede"/>} />
        <Route path="*" element={<Navigate to="error"/>} />
        
      </Routes>
    
  )
}
