import React, { useEffect, useState } from 'react'
import { Button, FormControl, InputGroup } from 'react-bootstrap-v5'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { ApplicationState } from '../../../store'
import { loadBloodcenterRequest, loadBloodcentersRequest, searchBloodcenterRequest } from '../../../store/ducks/bloodcenter/actions'
import { BloodcenterState } from '../../../store/ducks/bloodcenter/types'
import { searchCampaignsRequest } from '../../../store/ducks/campaign/actions'
import { CampaignState } from '../../../store/ducks/campaign/types'
import { searchRequest } from '../../../store/ducks/component/actions'
import { ComponentState } from '../../../store/ducks/component/types'
import { PageTitle } from '../../design/layout/core'
import Loading from '../../design/loading'
import { SearchWidget } from './SearchWidget'

type Props = {
  bc: BloodcenterState
}
const ModulesPage: React.FC<React.PropsWithChildren<Props>> = ({bc}) => (
  <>
    {/* begin::Row */}
    <div className='row g-5 gx-xxl-12'>
      <div className='col-xxl-12'>
        <SearchWidget bc={bc} className='card-xxl-stretch mb-5 mb-xxl-8' />
      </div>
    </div>
    {/* end::Row */}
  </>
)

const Bloodcenters = () => {

  useEffect(() => {
    dispatch(loadBloodcentersRequest())
  
}, [])

  const intl = useIntl()
  const [searchText, setSearch] = useState('')
  const bc = useSelector((state: ApplicationState) => state.bloodcenter)
  const dispatch = useDispatch()
  console.log("Search", bc)
//   useEffect(() => {
//     if(searchText)
//         dispatch(searchRequest(searchText))
//   }, [searchText])
  document.title = 'Hemocentros | Salve mais um';


  const handleSubmit = (event: React.ChangeEvent<HTMLFormElement>) => {
    const form = event.currentTarget
    event.preventDefault()
    if (form.checkValidity() === false) {
      event.stopPropagation()
    }
    console.log("Searching...", searchText)
    dispatch(searchBloodcenterRequest(searchText))

  }

  
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.BLOODCENTERS'})}</PageTitle>
      <form onSubmit={handleSubmit}>
        <InputGroup className="mb-3">
            <FormControl
            placeholder="Procure por nome ou cidade."
            aria-label="Digite um nome ou cidade."
            aria-describedby="basic-addon2"
            
            onChange={(e:any) => setSearch(e.target.value)}
            />
            <button id="button-addon2" className="btn btn-secondary">Procurar</button>
        </InputGroup>
        </form>

        {bc.loading || bc.loadingSearch?
        <Loading />
        :
        <ModulesPage bc={bc} />
        }       
    </>
  )
}

export default Bloodcenters