import clsx from 'clsx'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../../../../../store'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import {
  // HeaderNotificationsMenu,
  HeaderUserMenu,
  // QuickLinks
} from '../../../partials'
import { MenuItem } from './MenuItem'
import { useLayout } from '../../core'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  // toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px'
// toolbarButtonIconSizeClass = 'svg-icon-1'


const Topbar: FC<React.PropsWithChildren<unknown>> = () => {
  const { config } = useLayout()
  const me = useSelector((state: ApplicationState) => state.me)

  const intl = useIntl()
  if (!me.logged)
    return (
      <div className='d-flex align-items-stretch flex-shrink-0'>
        {/* begin::User */}
        <div
          className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
          id='kt_header_user_menu_toggle'
        >
          <Link className='btn btn-primary btn-sm' to={'/auth/login'}>{intl.formatMessage({ id: 'MENU.CREATECAMPAIGNS' })}</Link>
        </div>
      </div>
    )
  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      {/* begin::User */}
      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        id='kt_header_user_menu_toggle'
      >
        <Link className='btn btn-primary btn-sm' to={'/campaigns'}>{intl.formatMessage({ id: 'MENU.CREATECAMPAIGNS' })}</Link>
      </div>
      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        style={{ zIndex: 99 }}
        id='kt_header_user_menu_toggle'
      >
        {/* begin::Toggle */}
        <div
          className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          {/* <img src={toAbsoluteUrl('/media/avatars/blank.png')} alt='salvemaisum' /> */}

          <img
            src={me.me.profile?.image?.includes('https://') ? me.me.profile?.image : '../../files/' + me.me.profile?.image}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = "https://salvemaisum.com.br/media/guestuser.jpg";
            }}
            alt='salvemaisum'
          />


        </div>
        <HeaderUserMenu />
        {/* end::Toggle */}
      </div>
      {/* end::User */}

      {/* begin::Aside Toggler */}
      {/* config.header.left === 'menu' && (
        <div className='d-flex align-items-center d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
            id='kt_header_menu_mobile_toggle'
          >
            <KTSVG path='/media/icons/duotune/text/txt001.svg' className='svg-icon-1' />
          </div>
        </div>
      ) */}
    </div>
  )
}

export { Topbar }
