import React, { useState, useEffect, useRef } from 'react'
import { Form, Button } from 'react-bootstrap-v5'
import { useSelector, useDispatch } from 'react-redux'
import { ApplicationState } from '../../../../store'
import { updateCampaignRequest } from '../../../../store/ducks/campaign/actions'
import { Campaign } from '../../../../store/ducks/campaign/types'
import { loadCityRequest } from '../../../../store/ducks/city/actions'
//import {CKEditor} from 'ckeditor4-react'
import { loadStateRequest } from '../../../../store/ducks/state/actions'
import Loading from '../../../design/loading'
import api from '../../../../services/api'
import { Bloodcenter } from '../../../../store/ducks/bloodcenter/types'
import { loadBloodcentersRequest } from '../../../../store/ducks/bloodcenter/actions'
import _ from 'lodash'
import { useNavigate } from 'react-router-dom'

import ReactCrop, { centerCrop, Crop, makeAspectCrop, PixelCrop } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { useDebounceEffect } from './useDebounceEffect'
import { canvasPreview } from './canvasPreview'
import Resizer from "react-image-file-resizer";


const MOMENT = require('moment')
require("moment-duration-format");

interface handleCloseProps {
  handleClose: () => void
  child: Campaign
}

// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number,
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  )
}

const Update = ({ handleClose, child }: handleCloseProps) => {
  const dispatch = useDispatch()
  const [validated, setValidated] = useState(false)
  const me = useSelector((state: ApplicationState) => state.me)

  const [imgSrc, setImgSrc] = useState('')
  const [croppedImage, setCroppedImage] = useState<any>('')
  const previewCanvasRef = useRef<HTMLCanvasElement>(null)
  const [crop, setCrop] = useState<Crop>()
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
  const [scale, setScale] = useState(1)
  const [rotate, setRotate] = useState(0)
  const [aspect, setAspect] = useState<number | undefined>(1 / 1) //16 / 9
  const imgRef = useRef<HTMLImageElement>(null)

  function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined) // Makes crop preview update between images.
      const reader = new FileReader()
      reader.addEventListener('load', () =>
        setImgSrc(reader.result?.toString() || ''),
      )
      reader.readAsDataURL(e.target.files[0])
      setSelectedFile(e.target.files[0])
      
      //setIsSelected(true)
    } else {
      //setSelectedFile(e.target.files[0])
      setImgSrc('')
    }
  }
  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget
      setCrop(centerAspectCrop(width, height, aspect))
      //console.log("ei?")
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        //console.log("hum?")
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate,
        ).then(() => {
          //console.log("FOI..?", previewCanvasRef)

          previewCanvasRef.current?.toBlob((file: any) => {
            console.log("FILE", file)
            try {
              Resizer.imageFileResizer(
                file,
                800,
                800,
                "JPEG",
                100,
                0,
                (uri) => {
                  console.log("Nova imagem:", uri);
                  setCroppedImage(uri)
                },
                "blob"
              );

              
            } catch (error) {
              console.log("error", error)
            }
            

            // let url = URL.createObjectURL(file);
            // console.log("URL", url)
          }, "image/jpeg");

        })
      }
    },
    100,
    [completedCrop, scale, rotate],
  )

  const [name, setName] = useState('')
  const [bloodtype, setBloodtype] = useState('')
  const [image, setImage] = useState('')
  const [bcname, setBcname] = useState('')
  const [bcaddress, setBcaddress] = useState('')
  const [bccity, setBccity] = useState("")
  const [bcstate, setBcstate] = useState("")
  const [bccountry, setBccountry] = useState('')

  const [noBloodcenter, setNoBloodcenter] = useState(false)
  const [selectedFile, setSelectedFile] = useState<any>()

  const [description, setDescription] = useState('')
  const [bloodCenter, setBloodCenter] = useState("")

  // const [isFilePicked, setIsFilePicked] = useState(false)
  //const [isSelected, setIsSelected] = useState(false)
  const [preview, setPreview] = useState<any>()
  //const [ckEditor, setCkEditor] = useState(false)
  const state = useSelector((state: ApplicationState) => state.state)
  const city = useSelector((state: ApplicationState) => state.city)
  const bc = useSelector((state: ApplicationState) => state.bloodcenter)
  const [separated, setSeparated] = useState<any>([])

  //const navigate = useNavigate()
  useEffect(() => {

    dispatch(loadStateRequest()) //Puxa componentes com seus filhos primários
    dispatch(loadCityRequest('' + child.stateParent?.id!))

    setName(child.name!)
    setBloodtype(child.bloodtype!)
    setImage(child.image!)
    // setIsSelected(true)
    // setSelectedFile(child.image!)
    setBcname(child.bcname!)
    setBcaddress(child.bcaddress!)
    setBccity(child.cityParent?.id ? '' + child.cityParent?.id! : "")
    setBcstate(child.stateParent?.id ? '' + child.stateParent?.id! : "")

    setBloodCenter(child.bcParent?.id ? '' + child.bcParent?.id! : "")
    setDescription(child.description!)

    setNoBloodcenter(child.bcParent?.id ? false : true)


    //setBccountry(child.bccountry!)

    dispatch(loadBloodcentersRequest()) //Puxa componentes com seus filhos primários
    if (bc.data.length)
      separaDropdown(bc.data)
    //Carrega estados e cidades desse estado




  }, [child.name, bc.data.length])

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    //console.log('submit', campaign.data.id)
    const form = event.currentTarget
    event.preventDefault()
    if (form.checkValidity() === false) {
      event.stopPropagation()
    }
    setValidated(true)
    if (name && bloodtype) { //bloodcenter
      
      if (selectedFile) { //selectedFile
        //Se selecionou arquivo novo
        console.log("Selecionado novo arquivo")
        const formdata = new FormData()
        formdata.append('file', croppedImage, 'salvemaisum.jpg') //selectedFile.name
        console.log('[formData]', formdata)
        console.log('selectedFile', selectedFile)
        api.post('/upload', formdata, {})
          .then((res: any) => {

            //var data = new Date()
            let campaignToUpdate: Campaign = {}
            if (noBloodcenter) {
              campaignToUpdate = {
                id: child.id,
                name,
                bloodtype,
                user_id: me.me.id!,
                image: res.data.filename,
                bcname,
                bcaddress,
                bccity,
                bcstate,
                //bccountry,
                //created_at: (data.getTime() / 1000).toString(),
                status: 1,
                bcParent: {
                  id: null,
                },
                description
              }
            } else {
              campaignToUpdate = {
                id: child.id,
                name,
                bloodtype,
                user_id: me.me.id!,
                image: res.data.filename,
                bcname: '',
                bcaddress: '',
                bccity: null,
                bcstate: null,
                cityParent: {
                  id: null
                },
                stateParent: {
                  id: null
                },
                //bccountry,
                //created_at: (data.getTime() / 1000).toString(),
                status: 1,
                bcParent: {
                  id: +bloodCenter,
                },
                description
              }
            }


            console.log('------------------ COMPONENT TO UPDATE', campaignToUpdate)
            dispatch(updateCampaignRequest(campaignToUpdate))
            handleClose()

          })
      } else {
        //Se não selecionou nenhuma foto nova:
        console.log("Nao selecionou nenhum arquivo")
        var data = new Date()
        let campaignToUpdate: Campaign = {}

        if (noBloodcenter) {
          campaignToUpdate = {
            id: child.id,
            name,
            bloodtype,
            user_id: me.me.id!,
            bcname,
            bcaddress,
            bccity,
            bcstate,
            //bccountry,
            // created_at: (data.getTime() / 1000).toString(),
            status: 1,
            bcParent: {
              id: null,
            },
            description
          }
        } else {
          campaignToUpdate = {
            id: child.id,
            name,
            bloodtype,
            user_id: me.me.id!,
            bcname: '',
            bcaddress: '',
            bccity: null,
            bcstate: null,
            cityParent: {
              id: null
            },
            stateParent: {
              id: null
            },
            //bccountry,
            // created_at: (data.getTime() / 1000).toString(),
            status: 1,
            bcParent: {
              id: +bloodCenter,
            },
            description
          }
        }


        console.log('------------------ COMPONENT TO UPDATE', campaignToUpdate)
        dispatch(updateCampaignRequest(campaignToUpdate))
        handleClose()

        //navigate('/campanha/'+child.id)

      }
    }
    //alert("Ok")


  }
  // const changeHandler = (event: any) => {
  //   setSelectedFile(event.target.files[0])
  //   setIsSelected(true)
  //   const objectUrl = URL.createObjectURL(event.target.files[0])
  //   setPreview(objectUrl)
  // }

  const setState = (id: string) => {
    setBcstate(id)
    setBccity('')
    dispatch(loadCityRequest(id))
  }
  const separaDropdown = (data: Bloodcenter[]) => {
    let separated: any;

    separated = _.groupBy(data, (d: any) => d.region)
    //Voltar o 3/4 para o lugar
    // console.log("primeiro", separated)
    separated = _.reduce(
      separated,
      (acc: any, next: any, index: any) => {

        acc.push({
          title: index,
          data: next,
        });
        return acc;
      },
      [],
    );

    let selection: any[] = []
    separated.map((selectOptions: any) => {
      selection.push({ id: 0, name: selectOptions.title, disabled: true })
      selectOptions.data.map((item: any) => {
        selection.push(item)
        //console.log("itemxx", item)
      })

    })
    console.log("SEPARATED", separated)
    console.log("selection", selection)

    setSeparated(selection)
  }

  return (
    <>
      <Form validated={validated} onSubmit={handleSubmit}>
        <div className='row g-5 gx-xxl-12'>
          <div className='col-xxl-6'>
            <h5>Dados de quem receberá sangue</h5><br />
            <Form.Group controlId='fromName'>
              <Form.Label>Nome completo</Form.Label>
              <Form.Control
                placeholder=''
                required
                value={name}
                onChange={(e: any) => setName(e.target.value)}
              />
              <Form.Control.Feedback type='invalid'>Por favor informe o nome</Form.Control.Feedback>
            </Form.Group>
            <br />

            <Form.Group controlId="formBasicSelect">
              <Form.Label>Tipo Sanguineo</Form.Label>
              <Form.Control
                required
                as="select"
                value={bloodtype}
                onChange={(e: any) => setBloodtype(e.target.value)}
              >
                <option value="" selected disabled hidden>Selecione um tipo sanguíneo</option>
                <option value="1" selected={bloodtype === '1' ? true : false}>O-</option>
                <option value="2" selected={bloodtype === '2' ? true : false}>O+</option>
                <option value="3" selected={bloodtype === '3' ? true : false}>A-</option>
                <option value="4" selected={bloodtype === '4' ? true : false}>A+</option>
                <option value="5" selected={bloodtype === '5' ? true : false}>B-</option>
                <option value="6" selected={bloodtype === '6' ? true : false}>B+</option>
                <option value="7" selected={bloodtype === '7' ? true : false}>AB-</option>
                <option value="8" selected={bloodtype === '8' ? true : false}>AB+</option>
              </Form.Control>
            </Form.Group>


            <br />

            <Form.Group>
              <Form.Label>Selecione uma foto</Form.Label>
              <Form.Control
                name='image'
                id='image'
                type='file'
                accept='image/*'
                onChange={onSelectFile}
                
              />
              <Form.Control.Feedback type='invalid'>Selecione um arquivo</Form.Control.Feedback>
            </Form.Group>
            <br />
            
            <div>
              <label htmlFor="image">
                <a style={{ cursor: 'pointer' }}>
                  {image && !imgSrc && <img
                    src={'../../files/' + image}
                    style={{ width: '50%' }}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = "https://salvemaisum.com.br/media/guestuser.jpg";
                    }}
                  />
                  }
                  {selectedFile && <img src={preview} style={{ width: '50%' }} />}
                </a>
              </label>
            </div>
            
            <div className='row g-5 gx-xxl-12'>
                  <div className='col-6'>
                    {!!imgSrc && (
                      <div>
                        <div>Posicione a foto</div>
                        <ReactCrop
                          crop={crop}
                          onChange={(_, percentCrop) => setCrop(percentCrop)}
                          onComplete={(c) => setCompletedCrop(c)}
                          aspect={aspect}
                          maxHeight={350}
                          maxWidth={350}

                        >
                          <img
                            ref={imgRef}
                            alt="Crop me"
                            src={imgSrc}
                            style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                            onLoad={onImageLoad}
                          />
                        </ReactCrop>
                      </div>
                    )}
                  </div>
                  <div className='col-6'>
                  <div>
                    {!!completedCrop && !!imgSrc && (
                      <div>
                        <div>Prévia</div>
                        <canvas
                          ref={previewCanvasRef}
                          style={{
                            border: '1px solid black',
                            objectFit: 'contain',
                            width: completedCrop.width,
                            height: completedCrop.height,
                          }}
                        />
                      </div>
                    )}
                  </div>
                  </div>
            </div>
            
            


              
              





            <br /><br />
          </div>
          <div className='col-xxl-6'>
            <h5>Dados do Hemocentro</h5><br />
            {noBloodcenter && <div><Form.Group controlId='fromName'>
              <Form.Label>Nome do Hemocentro</Form.Label>
              <Form.Control placeholder='' value={bcname} onChange={(e: any) => setBcname(e.target.value)} required />
              <Form.Control.Feedback type='invalid'>Por favor informe o nome do hemocentro</Form.Control.Feedback>
            </Form.Group>
              <br />
              <Form.Group controlId='fromName'>
                <Form.Label>Endereço</Form.Label>
                <Form.Control placeholder='' value={bcaddress} onChange={(e: any) => setBcaddress(e.target.value)} required />
                <Form.Control.Feedback type='invalid'>Por favor informe o endereço do hemocentro</Form.Control.Feedback>
              </Form.Group>
              <br />
              <Form.Group controlId="formBasicSelect">
                <Form.Label>Estado</Form.Label>

                {state.loading ? <Loading /> :
                  <Form.Control
                    as="select"
                    value={bcstate}
                    onChange={(e: any) => setState(e.target.value)}
                    required
                  >
                    <option value="" selected disabled hidden>Selecione</option>
                    {state.data.map(st => {
                      return <option value={'' + st.id} selected={st.id === +bcstate}>{st.name}</option>
                    })}
                  </Form.Control>
                }
              </Form.Group>
              <br />
              <Form.Group controlId="formBasicSelect">
                <Form.Label>Cidade</Form.Label>
                {city.loading ? <Loading /> :
                  <Form.Control
                    as="select"
                    value={bccity}
                    onChange={(e: any) => setBccity(e.target.value)}
                    required
                  >
                    <option value="" selected disabled hidden>Selecione</option>
                    {city.data.map((ct) => {
                      return <option value={'' + ct.id} selected={ct.id === +bccity}>{ct.name}</option>
                    })}
                  </Form.Control>
                }
              </Form.Group></div>}


            {!noBloodcenter && <Form.Group controlId="formBasicSelect">
              <Form.Label>Selecione o hemocentro mais próximo da doação</Form.Label>
              {bc.loading ? <Loading /> :
                <Form.Control
                  required={noBloodcenter ? true : false}
                  as="select"
                  value={bloodCenter}
                  onChange={(e: any) => setBloodCenter(e.target.value)}
                >
                  <option value="" selected disabled hidden>Selecione um hemocentro</option>
                  {separated.map((bcitem: any) => {
                    return bcitem.disabled ?
                      <option style={{ backgroundColor: '#EAEAEA', fontSize: 19 }} value={bcitem.id} disabled={bcitem.disabled}>{bcitem.name}</option>
                      :
                      <option value={bcitem.id} disabled={bcitem.disabled}>{bcitem.name} - {bcitem.stateParent?.name}</option>
                  })}
                </Form.Control>
              }
            </Form.Group>}
            <br />
            <a href='#' onClick={() => setNoBloodcenter(!noBloodcenter)}>{noBloodcenter ? 'Selecionar um hemocentro na lista' : 'O hemocentro não está na lista? Clique aqui'}</a>
            <br /><br />
            <Form.Group controlId='fromName'>
              <Form.Label>Informações adicionais</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                placeholder=''
                value={description}
                onChange={(e: any) => setDescription(e.target.value)}
              />
              {/* <Form.Control.Feedback type='invalid'>Por favor informe o nome</Form.Control.Feedback> */}
            </Form.Group>
            <br />


          </div>
        </div>




        <Button size='sm' variant='primary' type='submit' className='float-right'>
          Salvar
        </Button>
      </Form>
    </>
  )
}
export default Update
