import React, {FC, useEffect, useState, useRef} from 'react'
import {PageTitle} from '../design/layout/core'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component';

import { CampaignWidget } from './CampaignWidget'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../../store'
import { useDispatch } from 'react-redux'
import { loadCampaignsRequest } from '../../store/ducks/campaign/actions'
import Loading from '../design/loading';
import { CompatibilityWidget } from './compatibility/CompatibilityWidget';
import { toAbsoluteUrl } from '../design/helpers';
// import Loading from '../design/loading'

const bloodtypetext = (bloodtype: string) => {
  switch (bloodtype) {
    case '1':
      return "O-"
    case '2':
      return "O+"
    case '3':
      return "A-"
    case '4':
      return "A+"
    case '5':
      return "B-"
    case '6':
      return "B+"
    case '7':
      return "AB-"
    case '8':
      return "AB+"
    default:
      return "Invalido"
  }
}

const TrainningPage: FC<React.PropsWithChildren<unknown>> = () => {
  const me = useSelector((state: ApplicationState) => state.me)
  const component = useSelector((state: ApplicationState) => state.component)
  const campaign = useSelector((state: ApplicationState) => state.campaign)
  

  const dispatch = useDispatch()
  const [hasMore, setHasMore] = useState(true);
  //const ref = useRef(1)
  //const [page, setPage] = useState(1);

  // console.log("ME AQUI", me)
  // console.log("campaigns", campaign)
  const intl = useIntl()

  const changePage = () => {
    console.log("total", campaign.countTotal)
    console.log("tamanho da lista", campaign.data.length)
    campaign.page = campaign.page! + 1;
    if(campaign.countTotal! !== campaign.data.length) {
      console.log("page", campaign.page)
      dispatch(loadCampaignsRequest(campaign.page,45))
      console.log("carregando mais...")
      setHasMore(true)
    }else{
      console.log("Nao vou carregar mais.")
      setHasMore(false)
    }
    
  }

  const refresh = () => {
    console.log('refresh')
  }

  useEffect(() => {
    // console.log("############ Loading component hey...", { module_id, class_id, me })
    if(campaign.page === 0) {
      campaign.page = campaign.page! + 1;
      console.log("PAGE USEEFFECT", campaign.page)
      dispatch(loadCampaignsRequest(campaign.page,45))
    }

  }, [me.me, campaign.filter])
  document.title = 'Salve mais um';

  // if(campaign.loading) return <Loading/>

  return (
    <>
    <CompatibilityWidget />
    {/* <div className='d-lg-none' style={{position: 'fixed', zIndex: 99, bottom: 0, width: '100%', display:'flex', justifyContent: 'center'}}>
      <Link to={'/campaigns'}>
        <img src={toAbsoluteUrl('/media/createcampaign.png')} alt='' />
      </Link>
    </div> */}
    <h2 className='text-center pb-5'>{campaign.bloodSelected? campaign.count + " campanhas compativeis com " + bloodtypetext(campaign.bloodSelected) : campaign.count + " motivos para doar sangue"}</h2>
    {/* {campaign.loading && <div><Loading/><br/></div>} */}
    <InfiniteScroll
        dataLength={campaign.data.length} //This is important field to render the next data
        next={changePage}
        hasMore={hasMore}
        loader={<Loading/>}
        endMessage={
          <p style={{ textAlign: 'center' }}>
            <b>Todas as campanhas foram carregadas.</b>
          </p>
        }
        className={'row gy-5 g-xl-8'}
        
        // below props only if you need pull down functionality
        // refreshFunction={refresh}
        // pullDownToRefresh
        // pullDownToRefreshThreshold={50}
        // pullDownToRefreshContent={
        //   <h3 style={{ textAlign: 'center' }}>&#8595; Pull down to refresh</h3>
        // }
        // releaseToRefreshContent={
        //   <h3 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h3>
        // }
      >

        {/* TESTE: {ref.current} */}
      {/* <button onClick={()=>changePage()}>change</button> */}
     
      {campaign.data.map((cp => {

      if(campaign.filter?.includes(+cp.bloodtype!))
        return (
          
          <div className='col-xxl-4' key={cp.id}>
            
            <Link to={'/campanha/' + cp.id}>
              <CampaignWidget
                name={'' + cp.name}
                className='card-xl-stretch mb-xl-8'
                chartColor='blue'
                chartHeight='200px'
                strokeColor='#cb1e46'
                modules='7'
                classes='25'
                nps='5'
                progresso='100%'
                img={'../../files/' + cp.image}
                //img={'https://salvemaisum.com.br/' + campaign.image}
                height={350}
                locked={false}
                campaign={cp}
              />
            </Link>
          
          </div>
        )
      }))}
    </InfiniteScroll>
    </>
  )
}

const Trainning: FC<React.PropsWithChildren<unknown>> = () => {
  const intl = useIntl()
  return (
    <>
      {/* <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.SALVEMAISUM'})}</PageTitle> */}
      <PageTitle breadcrumbs={[]}>Salve Mais Um</PageTitle>
      <TrainningPage />
    </>
  )
}
export {Trainning}
