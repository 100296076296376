import React, {FC, useEffect} from 'react'
import {PageTitle} from '../../design/layout/core'
import {useIntl} from 'react-intl'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../../../store'
import { useDispatch } from 'react-redux'
import { loadCampaignRequest } from '../../../store/ducks/campaign/actions'
import { useParams } from 'react-router-dom'
import Loading from '../../design/loading'
import { Campaign, CampaignState } from '../../../store/ducks/campaign/types'
import {
  EmailShareButton,
  FacebookShareButton,
  FacebookShareCount,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,
  TwitterIcon,
  FacebookIcon,
  LinkedinIcon,
  PinterestIcon,
  TelegramIcon,
  EmailIcon,
  WhatsappIcon,

} from "react-share";
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet-async';
import { loadBloodcenterRequest } from '../../../store/ducks/bloodcenter/actions'
import { Bloodcenter, BloodcenterState } from '../../../store/ducks/bloodcenter/types'

const MOMENT = require('moment')
type ParamTypes = {
  id: string
}

type Props = {
  bc: BloodcenterState
}
const doaPara = (bloodtype: string) => {
  switch (bloodtype) {
    case '1':
      return "A+, A-, B+, B-, AB+, AB-, O+, O-" //O-
    case '2':
      return "A+, B+, AB+, AB-" //O+
    case '3':
      return "A+, A-, AB+, AB-" //A-
    case '4':
      return "A+, AB+" //A+
    case '5':
      return "B+, B-, AB+, AB-" //B-
    case '6':
      return "B+, AB+" //B+
    case '7':
      return "AB+, AB-" //AB-
    case '8':
      return "AB+" //AB+
    default:
      return "Invalido"
  }
}

const recebeDe = (bloodtype: string) => {
  switch (bloodtype) {
    case '1':
      return "O-" //O-
    case '2':
      return "O+, O-" //O+
    case '3':
      return "A-, O-" //A-
    case '4':
      return "A+, A-, O+, O-" //A+
    case '5':
      return "B-, O-" //B-
    case '6':
      return "B+, B-, O+, O-" //B+
    case '7':
      return "A-, B-, AB-, O-" //AB-
    case '8':
      return "A+, A-, B+, B-, AB+, AB-, O+, O-" //AB+
    default:
      return "Invalido"
  }
}

const bloodtypetext = (bloodtype: string) => {
  switch (bloodtype) {
    case '1':
      return "O-"
    case '2':
      return "O+"
    case '3':
      return "A-"
    case '4':
      return "A+"
    case '5':
      return "B-"
    case '6':
      return "B+"
    case '7':
      return "AB-"
    case '8':
      return "AB+"
    default:
      return "Invalido"
  }
}
const ViewCampaignPage: FC<React.PropsWithChildren<Props>> = ({bc}) => {
  // let created_at_text = MOMENT(Number(campaign.campaign.created_at!) * 1000) //.format('DD/MM/YYYY HH:mm')
  // const title = `Ajude a salvar ${campaign.campaign.name} | Salve Mais Um`;
  // const url = window.location.href;
  // const gmapsurl = 'https://www.google.com.br/maps/place/' + campaign.campaign.bcaddress + ' ' + campaign.campaign.cityParent?.name + ' ' + campaign.campaign.stateParent?.state
  // const gmurl = gmapsurl.replaceAll(' ', '+')
  
  // console.log("url", gmurl)

  const iconSize = 48;
  const url = window.location.href;
  const gmapsurl = 'https://www.google.com.br/maps/place/' + bc.bloodcenter.address
  const gmurl = gmapsurl.replaceAll(' ', '+')
  return (
    <>
      {/* begin::Row */}
      
      <div className='row gy-5 g-xl-8'>
          <div className='col-xxl-3'>
          
            <div className={`card card-xxl-stretch mb-5 mb-xxl-6`}>
              <img 
                // src={'../../files/' + bc.bloodcenter.image} 
                src={'https://salvemaisum.com.br/files/' + bc.bloodcenter.image} 
                
                style={{width: '100%'}} 
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src="https://salvemaisum.com.br/media/guestuser.jpg";
                }}
              />
              {/* begin::Header */}
              <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bolder fs-3'>
                  {bc.bloodcenter.name}
                  </span>
                </h3>
              </div>
              <div style={{ paddingLeft: '2.25rem', paddingRight: '2.25rem' }}>
                {bc.bloodcenter.address}
                <br/><br/>
                {bc.bloodcenter.cityParent?.name}
                <br/>
                {bc.bloodcenter.stateParent?.name}
                <br/>
                <a target={'_blank'} href={gmurl}>
                  Abrir no Google Maps
                </a>
                {/* Pode receber doação de: {recebeDe(campaign.campaign.bloodtype!)} */}
                <br/>
                {/* Campanha criada em: {created_at_text.format('DD/MM/YYYY HH:mm')} */}
                <br/><br/>
              </div>
            </div>
          </div>

          <div className='col-xxl-9'>

          <div className={`card card-xxl-stretch mb-5 mb-xxl-6`}>
            {/* begin::Header */}
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>
                  Campanhas criadas aqui
                </span>
                <span className='text-muted mt-1 fw-bold fs-7'>
                  Clique no link para ser redirecionado
                </span>
              </h3>
            </div>
            {/* end::Header */}
            {/* begin::Body */}
            <div className='card-body py-3'>
              <div className='tab-content'>
                {/* begin::Tap pane */}
                <div className='tab-pane fade show active' id='kt_table_widget_5_tab_1'>
                  {/* begin::Table container */}
                  <div className='table-responsive'>
                    {/* begin::Table */}
                    <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                      {/* begin::Table head */}
                      <thead>
                        <tr className='border-0'>
                          <th className='p-0 w-100px'></th>
                          <th className='p-0 min-w-150px'></th>
                        </tr>
                      </thead>
                      {/* end::Table head */}
                      {/* begin::Table body */}
                      <tbody>
                        {bc.bloodcenter.campaigns?.map((component: any, index: any) => {
                          return (
                            <tr>
                              <td>
                              <Link
                                  className='fs-6 text-gray-800 text-hover-primary fw-bolder'
                                  to={
                                    '/campanha/' +
                                    component.id
                                  }
                                  style={{display: 'flex'}}
                                >
                                <img src={'../../files/' + component.image} style={{width: '100%'}} 
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src="https://salvemaisum.com.br/media/guestuser.jpg";
                                  }} />
                                </Link>
                              </td>
                              <td>
                                <Link
                                  className='fs-6 text-gray-800 text-hover-primary fw-bolder'
                                  to={
                                    '/campanha/' +
                                    component.id
                                  }
                                  style={{display: 'flex'}}
                                >
                                  {component.name}
                                </Link>
                                <span className='text-muted fw-bold d-block'>{bloodtypetext(component.bloodtype!)}</span>
                                <span className='text-muted fw-bold d-block'>Recebe sangue: {recebeDe(component.bloodtype!)}</span>
                                <span className='text-muted fw-bold d-block'>{component.bcname}</span>
                                {/* <span className='text-muted fw-bold d-block'>{component.cityParent!.name}/{component.stateParent!.state}</span> */}
                              </td>
                              
                              {/* <td className='text-end text-muted fw-bold'>{component.parentName}</td> */}
                              {/* component.search.map((component,index) => 
                                  <div key={index}>
                                    {component.parentName} {component.name} {component.id} {component.parentId} {component.firstId} {component.value_extra}
                                  </div>
                              ) */}
                            </tr>
                          )
                        })}
                      </tbody>
                      {/* end::Table body */}
                    </table>
                  </div>
                  {/* end::Table */}
                </div>
                {/* end::Tap pane */}
              </div>
            </div>
            {/* end::Body */}
          </div>
          <br/>
        </div>
      </div>
      




      {/* end::Row */}
    </>
  )
}

const ViewBloodCenter: FC<React.PropsWithChildren<unknown>> = () => {
  const intl = useIntl()
  const me = useSelector((state: ApplicationState) => state.me)
  //const component = useSelector((state: ApplicationState) => state.component)
  const bc = useSelector((state: ApplicationState) => state.bloodcenter)
  const dispatch = useDispatch()
  let {id} = useParams<ParamTypes>()

  console.log("ME AQUI", me)
  console.log("bc", bc)
  

  useEffect(() => {
      // console.log("############ Loading component hey...", { module_id, class_id, me })
      // console.log("ID", id)
      // console.log("camp id", campaign.campaign.id)
      // console.log("dispatch?", +campaign.campaign.id! !== +id!)
      // if(campaign.campaign.name)
      //   document.title = 'Ajude a salvar '+ campaign.campaign.name;
      //if(+campaign.campaign.id! !== +id!) 
        dispatch(loadBloodcenterRequest(+id!))
    
  }, [])

  if(bc.loadingBloodcenter) return <Loading />
   
  return (
    <>
      {/* <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.SALVEMAISUM'})}</PageTitle> */}
      <PageTitle breadcrumbs={[]}>{bc.bloodcenter.name}</PageTitle>
      <ViewCampaignPage bc={bc} />
    </>
  )
}
export {ViewBloodCenter}
