/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react'
import {Link, useNavigate, useParams} from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../../../../store'
import { confirmUserRequest } from '../../../../store/ducks/me/actions'
import Loading from '../../../design/loading'

type ParamTypes = {
  email: string | undefined
  auth_key: string | undefined
}

const Confirm = () => {
  const {email, auth_key} = useParams<ParamTypes>()
  const me = useSelector((state: ApplicationState) => state.me)
  const dispatch = useDispatch()

  useEffect(() => {
    var data = new Date()
    dispatch(confirmUserRequest(email!, auth_key!, (data.getTime() / 1000).toString()))
  }, [auth_key, email, dispatch])

  return (

      <div className='mb-10 text-center'>
        {/* begin::Title */}
        <h1 className='text-dark mb-3'>Confirmação</h1>
        {/* end::Title */}
        {me.loadingconfirmation && <Loading/>}
        {!me.loadingconfirmation && me.confirmmsg}
        {!me.loadingconfirmation && me.confirmerror}
        <br/>
        {/* {email}
        {auth_key} */}
        {/* begin::Link */}
        <div className='text-gray-400 fw-bold fs-4'>
          {}
          <br/><br/>
          <Link to='/auth/login' className='link-primary fw-bolder' style={{marginLeft: '5px'}}>
            Fazer login
          </Link>
        </div>
        {/* end::Link */}
      </div>
      
  )
}
export default Confirm
