import React, {FC, useEffect, useState} from 'react'
import {PageTitle} from '../../design/layout/core'
import {useIntl} from 'react-intl'
import { Button, Form } from 'react-bootstrap-v5'
import { createcontactRequest } from '../../../store/ducks/contact/actions'
import { Contact as ContactRedux } from '../../../store/ducks/contact/types'
import { useDispatch } from 'react-redux'
import { ApplicationState } from '../../../store'
import { useSelector } from 'react-redux'


const ContactPage: FC<React.PropsWithChildren<unknown>> = () => {
  const [validated, setValidated] = useState(false)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')
  const dispatch = useDispatch()
  const contact = useSelector((state: ApplicationState) => state.contact)

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    //console.log('submit', campaign.data.id)
    
    const form = event.currentTarget
    event.preventDefault()
    if (form.checkValidity() === false) {
      event.stopPropagation()
    }
    setValidated(true)
    if (name && email && subject && message) {
      
        //Se não selecionou nenhuma foto nova:
        console.log("Nao selecionou nenhum arquivo")
        var data = new Date()
        const contact: ContactRedux = {
          name,
          email,
          subject,
          message,
          created_at: (data.getTime() / 1000),
          //status: 1,
        }

        console.log('------------------ CONTACT', contact)
        dispatch(createcontactRequest(contact))
      
    }

    
  }
  
  useEffect(() => {
    
  }, [])
  document.title = 'Contato | Salve mais um';
  console.log("contact", contact)
  
  return (
    <div className={`card card-xxl-stretch mb-5 mb-xxl-7`}>
        <div className='card-header border-0 pt-5'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bolder fs-3 mb-1'>Entre em contato</span>
              <span className='text-muted mt-1 fw-bold fs-7'>Queremos sua opinião</span>
            </h3>
        </div>
        <div className='card-body py-3'>
          <div className='col-xxl-12'>
          {contact.data.length?
          <div>
            Mensagem enviada com sucesso. Obrigado.
          </div>
          : 
            <Form validated={validated} onSubmit={handleSubmit}>
              <div className='row g-5 gx-xxl-12'>
                <div className='col-xxl-12'>
                  <Form.Group controlId='fromName'>
                    <Form.Label>Nome completo</Form.Label>
                    <Form.Control
                      placeholder=''
                      required
                      value={name}
                      onChange={(e:any) => setName(e.target.value)}
                    />
                    <Form.Control.Feedback type='invalid'>Por favor informe o nome</Form.Control.Feedback>
                  </Form.Group>
                  <br />
                  <Form.Group controlId='fromName'>
                    <Form.Label>E-mail</Form.Label>
                    <Form.Control
                      placeholder=''
                      required
                      value={email}
                      onChange={(e:any) => setEmail(e.target.value)}
                    />
                    <Form.Control.Feedback type='invalid'>Por favor informe o email</Form.Control.Feedback>
                  </Form.Group>
                  <br />
                  <Form.Group controlId='fromName'>
                    <Form.Label>Assunto</Form.Label>
                    <Form.Control
                      placeholder=''
                      required
                      value={subject}
                      onChange={(e:any) => setSubject(e.target.value)}
                    />
                    <Form.Control.Feedback type='invalid'>Por favor informe o assunto</Form.Control.Feedback>
                  </Form.Group>
                  <br />
                  <Form.Group controlId='fromName'>
                    <Form.Label>Mensagem</Form.Label>
                    <Form.Control
                      as="textarea" rows={6}
                      placeholder=''
                      required
                      value={message}
                      onChange={(e:any) => setMessage(e.target.value)}
                    />
                    <Form.Control.Feedback type='invalid'>Por favor informe a mensagem</Form.Control.Feedback>
                  </Form.Group>
                  <br />
                  
               
                    
                  </div>
                </div>
                

                

                <Button size='sm' variant='primary' type='submit' className='float-right'>
                  Enviar
                </Button>
              </Form>
              }
          </div>
          <br/>
        </div>
     </div>
  )
}

const Contact: FC<React.PropsWithChildren<unknown>> = () => {
  const intl = useIntl()
  return (
    <>
      {/* <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.SALVEMAISUM'})}</PageTitle> */}
      <PageTitle breadcrumbs={[]}>Contato</PageTitle>
      <ContactPage />
    </>
  )
}
export {Contact}
