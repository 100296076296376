/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Campaign, CampaignState } from '../../store/ducks/campaign/types'
import { User } from '../../store/ducks/me/types'
// import ApexCharts, {ApexOptions} from 'apexcharts'
import {
  // KTSVG,
  toAbsoluteUrl,
} from '../design/helpers'
// import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
// import {Dropdown1} from '../../content/dropdown/Dropdown1'
const MOMENT = require('moment')

// const doaPara = (bloodtype: string) => {
//   switch (bloodtype) {
//     case '1':
//       return "A+, A-, B+, B-, AB+, AB-, O+, O-" //O-
//     case '2':
//       return "A+, B+, AB+, AB-" //O+
//     case '3':
//       return "A+, A-, AB+, AB-" //A-
//     case '4':
//       return "A+, AB+" //A+
//     case '5':
//       return "B+, B-, AB+, AB-" //B-
//     case '6':
//       return "B+, AB+" //B+
//     case '7':
//       return "AB+, AB-" //AB-
//     case '8':
//       return "AB+" //AB+
//     default:
//       return "Invalido"
//   }
// }

const recebeDe = (bloodtype: string) => {
  switch (bloodtype) {
    case '1':
      return "O-" //O-
    case '2':
      return "O+, O-" //O+
    case '3':
      return "A-, O-" //A-
    case '4':
      return "A+, A-, O+, O-" //A+
    case '5':
      return "B-, O-" //B-
    case '6':
      return "B+, B-, O+, O-" //B+
    case '7':
      return "A-, B-, AB-, O-" //AB-
    case '8':
      return "A+, A-, B+, B-, AB+, AB-, O+, O-" //AB+
    default:
      return "Invalido"
  }
}

const bloodtypetext = (bloodtype: string) => {
  switch (bloodtype) {
    case '1':
      return "O-"
    case '2':
      return "O+"
    case '3':
      return "A-"
    case '4':
      return "A+"
    case '5':
      return "B-"
    case '6':
      return "B+"
    case '7':
      return "AB-"
    case '8':
      return "AB+"
    default:
      return "Invalido"
  }
}

type Props = {
  className: string
  chartColor: string
  strokeColor: string
  chartHeight: string
  name: string
  modules: string
  classes: string
  nps: string
  progresso: string
  img: string
  height: number
  locked?: boolean
  campaign: Campaign
}

const CampaignWidget: React.FC<React.PropsWithChildren<Props>> = ({
  className,
  chartColor,
  chartHeight,
  strokeColor,
  name,
  modules,
  classes,
  nps,
  progresso,
  img,
  height,
  locked,
  campaign,
}) => {
  let created_at_text = MOMENT(Number(campaign.created_at) * 1000) //.format('DD/MM/YYYY HH:mm')
  return (
    <>
      <div className={`toHover card ${className}`}>
        {/* begin::Header */}
        <div
          className={
            locked
              ? `disabled-blog card-header wave border-0 py-5 bg-${chartColor}`
              : `card-header wave border-0 py-5 bg-${chartColor}`
          }
          style={{
            //justifyContent: 'center',
            // alignItems: 'flex-end',
            // flex: '1 1 0%',
            display: 'flex',
            backgroundImage: locked
              ? `url(${toAbsoluteUrl('/media/violaofeeling/cadeado.png')}), url(${img})`
              : `url(${img}), url(../../files/system/bg-campaign.jpg)`,

            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover, cover', //contain | cover
            backgroundPosition: 'center center',
            // color: 'rgba(255, 255, 255, 0.1)',
            height: height,
          }}
        >
          {/* <h3 className='card-title fw-bolder text-white' style={{height: height}}>{name}</h3> */}

          <div className='card-toolbar'>
            {/* begin::Menu *-/}
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-white btn-active-white btn-active-color- border-0 me-n3'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
          </button>
          <Dropdown1 />
          {/* end::Menu */}
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body p-0'>
          {/* begin::Stats */}
          <div className='card-p position-relative'>
            {/* begin::Row */}
            <div className='row'>
              <div className='col-9'>
                
                <div className='card-title fw-bolder text-dark'>{name} ({bloodtypetext(campaign.bloodtype!)})</div>
                <div className='card-title fw-bolder text-dark'>Precisa de: {recebeDe(campaign.bloodtype!)}</div>
                {campaign.bcParent?.id?
                  <div className='text-dark'>{campaign.bcParent.name} {campaign.bcParent?.cityParent?.name} {campaign.bcParent?.stateParent?.name}</div>
                :
                  <div className='text-dark'>{campaign.cityParent?.name + '/' + campaign.stateParent?.name }</div>
                }
              </div>
              <div className='col-3 text-center'>
                <img 
                  src={ campaign.parentUser?.image?.includes('https://') ? campaign.parentUser.image : '../../files/' + campaign.parentUser?.image} 
                  style={{borderRadius:100, width: 30}} 
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src="https://salvemaisum.com.br/media/guestuser.jpg";
                  }}
                />
                <div className='text-dark'>{campaign.parentUser?.name?.substring(0,11)}...</div>
                <div className='text-dark'>{created_at_text.format('DD/MM')}</div>
              </div>
            </div>
            {/* end::Row */}
            

            
            {/* <div className='text-dark'>{bcaddress + ' ' + bcname + ' ' + bccity + ' ' + bcstate }</div> */}
          </div>
          {/* end::Stats */}
        </div>
        {/* end::Body */}
      </div>
    </>
  )
}

export {CampaignWidget}
