import {call, put} from 'redux-saga/effects'
import api from '../../../services/api'

import {
  loadComponentRequest,
  loadComponentSuccess,
  loadComponentFailure,
  createComponentRequest,
  createComponentSuccess,
  createComponentFailure,
  updateComponentRequest,
  updateComponentSuccess,
  updateComponentFailure,
  deleteComponentRequest,
  deleteComponentSuccess,
  deleteComponentFailure,
  searchRequest,
  searchSuccess,
  searchFailure,

 
  // loadCourseRequest,
} from './actions'

import {Component} from './types'

// import { loadExtrasSuccess } from '../extras/actions'
import {Extras} from '../extras/types'
// import axios from 'axios';

//Load Component
export function* loadComponent(payload: ReturnType<typeof loadComponentRequest>) {
  try {
    console.log("chamou loadComponent", payload.payload)
    put(loadComponentRequest(payload.payload))
    const response: Component = yield call(api.get, 'components/' + payload.payload)
    yield put(loadComponentSuccess(response))
  } catch (error) {
    yield put(loadComponentFailure())
  }
}

//Create Component
export function* createComponent(payload: ReturnType<typeof createComponentRequest>) {
  try {
    const response: Component = yield call(api.post, 'components', payload.payload)
    yield put(createComponentSuccess(response))
  } catch (error: any) {
    yield put(createComponentFailure(error.response.data))
  }
}

//Update Component
export function* updateComponent(payload: ReturnType<typeof updateComponentRequest>) {
  try {
    put(updateComponentRequest(payload.payload))
    const response: Component = yield call(api.post, 'components', payload.payload)
    yield put(updateComponentSuccess(response))
  } catch (error) {
    yield put(updateComponentFailure())
  }
}

//Delete Component
export function* deleteComponent(payload: ReturnType<typeof deleteComponentRequest>) {
  try {
    const number: number = yield call(api.delete, 'components/' + payload.payload)
    yield put(deleteComponentSuccess(number))
  } catch (error) {
    yield put(deleteComponentFailure())
  }
}

//Load Component
export function* searchComponent(payload: ReturnType<typeof searchRequest>) {
  try {
    put(searchRequest(payload.payload))
    const response: Component = yield call(api.get, 'search/' + payload.payload)
    console.log("RESPONSE SEARCH", response)
    yield put(searchSuccess(response))
  } catch (error:any) {
    yield put(searchFailure(error.response))
  }
}