import {action} from 'typesafe-actions'
import {Bloodcenter, BloodcenterTypes} from './types'
import {Extras} from '../extras/types'

//Load
export const loadBloodcentersRequest = () =>
  action(BloodcenterTypes.LOAD_BLOODCENTERS_REQUEST)
export const loadBloodcentersSuccess = (data: Bloodcenter) =>
  action(BloodcenterTypes.LOAD_BLOODCENTERS_SUCCESS, data)
export const loadBloodcentersFailure = () => action(BloodcenterTypes.LOAD_BLOODCENTERS_FAILURE)

export const searchBloodcenterRequest = (text: string) =>
  action(BloodcenterTypes.SEARCH_BLOODCENTER_REQUEST, {text})
export const searchBloodcenterSuccess = (data: Bloodcenter) =>
  action(BloodcenterTypes.SEARCH_BLOODCENTER_SUCCESS, data)
export const searchBloodcenterFailure = () => action(BloodcenterTypes.SEARCH_BLOODCENTER_FAILURE)

export const loadBloodcenterRequest = (id: number) =>
  action(BloodcenterTypes.LOAD_BLOODCENTER_REQUEST, {id})
export const loadBloodcenterSuccess = (data: Bloodcenter) =>
  action(BloodcenterTypes.LOAD_BLOODCENTER_SUCCESS, data)
export const loadBloodcenterFailure = () => action(BloodcenterTypes.LOAD_BLOODCENTER_FAILURE)

//Create bc
export const createBloodcenterRequest = (bc: Bloodcenter) =>
  action(BloodcenterTypes.CREATE_BLOODCENTER_REQUEST, bc)
export const createBloodcenterSuccess = (data: Bloodcenter) =>
  action(BloodcenterTypes.CREATE_BLOODCENTER_SUCCESS, data)
export const createBloodcenterFailure = (err: any[]) =>
  action(BloodcenterTypes.CREATE_BLOODCENTER_FAILURE, err)

//Update bc
export const updateBloodcenterRequest = (bc: Bloodcenter) =>
  action(BloodcenterTypes.UPDATE_BLOODCENTER_REQUEST, bc)
export const updateBloodcenterSuccess = (data: Bloodcenter) =>
  action(BloodcenterTypes.UPDATE_BLOODCENTER_SUCCESS, data)
export const updateBloodcenterFailure = () => action(BloodcenterTypes.UPDATE_BLOODCENTER_FAILURE)

//Delete bc
export const deleteBloodcenterRequest = (id: number) =>
  action(BloodcenterTypes.DELETE_BLOODCENTER_REQUEST, id)
export const deleteBloodcenterSuccess = (id: number) =>
  action(BloodcenterTypes.DELETE_BLOODCENTER_SUCCESS, id)
export const deleteBloodcenterFailure = () => action(BloodcenterTypes.DELETE_BLOODCENTER_FAILURE)

