import {createStore, applyMiddleware, Store} from 'redux'
import createSagaMiddleware from 'redux-saga'
import rootReducer from './ducks/rootReducer'
import rootSaga from './ducks/rootSaga'
import {persistStore} from 'redux-persist'

import {UsersState} from './ducks/users/types'
import {MeState} from './ducks/me/types'
import {ComponentState} from './ducks/component/types'
import {ExtrasState} from './ducks/extras/types'
import { CampaignState } from './ducks/campaign/types'
import { StateState } from './ducks/state/types'
import { CityState } from './ducks/city/types'
import { ContactState } from './ducks/contact/types'
import { BloodcenterState } from './ducks/bloodcenter/types'

// import { AulaConcluidaState } from './ducks/aulaconcluida/types';

export interface ApplicationState {
  users: UsersState
  me: MeState
  component: ComponentState
  extras: ExtrasState
  campaign: CampaignState
  state: StateState,
  city: CityState,
  contact: ContactState,
  bloodcenter: BloodcenterState
}

const sagaMiddleware = createSagaMiddleware()
const store: Store<ApplicationState> = createStore(rootReducer, applyMiddleware(sagaMiddleware))

sagaMiddleware.run(rootSaga)

export const persistor = persistStore(store)

export default store
