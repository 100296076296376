import React, { useState, useEffect, useRef } from 'react'
import { Form, Button, Dropdown } from 'react-bootstrap-v5'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { ApplicationState } from '../../../../store'
import { Campaign } from '../../../../store/ducks/campaign/types'
import { createCampaignRequest } from '../../../../store/ducks/campaign/actions'
import { loadStateRequest } from '../../../../store/ducks/state/actions'
import Loading from '../../../design/loading'
import { loadCityRequest } from '../../../../store/ducks/city/actions'
//import {CKEditor} from 'ckeditor4-react'
import api from '../../../../services/api'
import { loadBloodcentersRequest } from '../../../../store/ducks/bloodcenter/actions'
import { Bloodcenter } from '../../../../store/ducks/bloodcenter/types'

import _ from 'lodash'

import ReactCrop, { centerCrop, Crop, makeAspectCrop, PixelCrop } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { useDebounceEffect } from './useDebounceEffect'
import { canvasPreview } from './canvasPreview'
import Resizer from "react-image-file-resizer";


const MOMENT = require('moment')
require("moment-duration-format");

// import { Modal } from 'react-bootstrap'
type ParamTypes = {
  id: string
}

interface handleCloseProps {
  handleClose: () => void
}

// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number,
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  )
}

const Create = ({ handleClose }: handleCloseProps) => {

  const [imgSrc, setImgSrc] = useState('')
  const [croppedImage, setCroppedImage] = useState<any>('')
  const previewCanvasRef = useRef<HTMLCanvasElement>(null)
  const [crop, setCrop] = useState<Crop>()
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
  const [scale, setScale] = useState(1)
  const [rotate, setRotate] = useState(0)
  const [aspect, setAspect] = useState<number | undefined>(1 / 1) //16 / 9
  const imgRef = useRef<HTMLImageElement>(null)

  function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined) // Makes crop preview update between images.
      const reader = new FileReader()
      reader.addEventListener('load', () =>
        setImgSrc(reader.result?.toString() || ''),
      )
      reader.readAsDataURL(e.target.files[0])

      setSelectedFile(e.target.files[0])
      //setIsSelected(true)
    } else {
      //setSelectedFile(e.target.files[0])
      setImgSrc('')
    }
  }
  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget
      setCrop(centerAspectCrop(width, height, aspect))
      //console.log("ei?")
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        //console.log("hum?")
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate,
        ).then(() => {
          //console.log("FOI..?", previewCanvasRef)

          previewCanvasRef.current?.toBlob((file: any) => {
            console.log("FILE", file)
            try {
              Resizer.imageFileResizer(
                file,
                800,
                800,
                "JPEG",
                100,
                0,
                (uri) => {
                  console.log("Nova imagem:", uri);
                  setCroppedImage(uri)
                },
                "blob"
              );

              
            } catch (error) {
              console.log("error", error)
            }
            

            // let url = URL.createObjectURL(file);
            // console.log("URL", url)
          }, "image/jpeg");

        })
      }
    },
    100,
    [completedCrop, scale, rotate],
  )

  const [name, setName] = useState('')
  const [bloodtype, setBloodtype] = useState('')
  //const [image, setImage] = useState('')
  const [bcname, setBcname] = useState('')
  const [bcaddress, setBcaddress] = useState('')
  const [bccity, setBccity] = useState('')
  const [bcstate, setBcstate] = useState('')
  const [bccountry, setBccountry] = useState('')

  const [description, setDescription] = useState('')
  const [bloodCenter, setBloodCenter] = useState()

  const [selectedFile, setSelectedFile] = useState<any>()
  const [noBloodcenter, setNoBloodcenter] = useState(false)


  // const [isFilePicked, setIsFilePicked] = useState(false)
  //const [isSelected, setIsSelected] = useState(false)
  //const [preview, setPreview] = useState<any>()
  //const [ckEditor, setCkEditor] = useState(false)

  // console.log("SELECTED FILE XX", selectedFile)
  // console.log("SELECTED croppedImage XX", croppedImage)





  const [validated, setValidated] = useState(false)
  const { id } = useParams<ParamTypes>()
  // const history = useHistory();
  const dispatch = useDispatch()
  // const component = useSelector((state: ApplicationState) => state.component)
  const me = useSelector((state: ApplicationState) => state.me)
  const bc = useSelector((state: ApplicationState) => state.bloodcenter)
  const state = useSelector((state: ApplicationState) => state.state)
  const city = useSelector((state: ApplicationState) => state.city)

  const [separated, setSeparated] = useState<any>([])




  const separaDropdown = (data: Bloodcenter[]) => {
    let separated: any;

    separated = _.groupBy(data, (d: any) => d.region)
    //Voltar o 3/4 para o lugar
    // console.log("primeiro", separated)
    separated = _.reduce(
      separated,
      (acc: any, next: any, index: any) => {

        acc.push({
          title: index,
          data: next,
        });
        return acc;
      },
      [],
    );

    let selection: any[] = []
    separated.map((selectOptions: any) => {
      selection.push({ id: 0, name: selectOptions.title, disabled: true })
      selectOptions.data.map((item: any) => {
        selection.push(item)
        //console.log("itemxx", item)
      })

    })
    console.log("SEPARATED", separated)
    console.log("selection", selection)

    setSeparated(selection)
  }

  useEffect(() => {
    dispatch(loadStateRequest()) //Puxa componentes com seus filhos primários
    dispatch(loadBloodcentersRequest()) //Puxa componentes com seus filhos primários
    if (bc.data.length)
      separaDropdown(bc.data)
  }, [bc.data.length])

  // console.log("state", state)
  // console.log("city", city)

  // console.log('Component inside - create', component)
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    const form = event.currentTarget
    event.preventDefault()
    if (form.checkValidity() === false) {
      event.stopPropagation()
    }
    setValidated(true)
    console.log("Create campaign")
    // console.log("Create campaign",selectedFile)
    // console.log("Create campaign",name)
    // console.log("Create campaign",bloodtype)
    // console.log("Create campaign",isSelected)
    // console.log("Create campaign",bloodCenter)

    if (selectedFile && name && bloodtype && imgSrc) { //bloodCenter
      const formdata = new FormData()
      formdata.append('file', croppedImage, 'salvemaisum.jpg') //selectedFile.name
      console.log('[formData]', formdata)
      console.log('selectedFile', selectedFile)
      api.post('/upload', formdata, {})
        .then((res) => {
          // then print response status
          var data = new Date()
          let campaign: Campaign = {};
          if (noBloodcenter) {
            campaign = {
              name,
              bloodtype,
              user_id: me.me.id!,
              image: res.data.filename,
              bcname,
              bcaddress,
              bccity,
              bcstate,
              bccountry,
              created_at: (data.getTime() / 1000).toString(),
              status: 1,
              // bcParent: {
              //   id: bloodCenter
              // },
              description
            }
          } else {
            campaign = {
              name,
              bloodtype,
              user_id: me.me.id!,
              image: res.data.filename,
              // bcname,
              // bcaddress,
              // bccity,
              // bcstate,
              // bccountry,
              created_at: (data.getTime() / 1000).toString(),
              status: 1,
              bcParent: {
                id: bloodCenter
              },
              description
            }
          }
          console.log('campaign to save:', campaign)
          dispatch(createCampaignRequest(campaign))
          handleClose()
          // history.goBack()
        })
    }


  }



  const setState = (id: string) => {
    setBcstate(id)
    dispatch(loadCityRequest(id!))
  }


  return (
    <>
      <Form validated={validated} onSubmit={handleSubmit}>
        <div className='row g-5 gx-xxl-12'>
          <div className='col-xxl-6'>
            <h5>Dados de quem receberá sangue</h5><br />
            <Form.Group controlId='fromName'>
              <Form.Label>Nome completo</Form.Label>
              <Form.Control
                placeholder=''
                required
                value={name}
                onChange={(e: any) => setName(e.target.value)}
              />
              <Form.Control.Feedback type='invalid'>Por favor informe o nome</Form.Control.Feedback>
            </Form.Group>
            <br />

            <Form.Group controlId="formBasicSelect">
              <Form.Label>Tipo Sanguineo</Form.Label>
              <Form.Control
                required
                as="select"
                value={bloodtype}
                onChange={(e: any) => setBloodtype(e.target.value)}
              >
                <option value="" selected disabled hidden>Selecione um tipo sanguíneo</option>
                <option value="1">O-</option>
                <option value="2">O+</option>
                <option value="3">A-</option>
                <option value="4">A+</option>
                <option value="5">B-</option>
                <option value="6">B+</option>
                <option value="7">AB-</option>
                <option value="8">AB+</option>
              </Form.Control>
            </Form.Group>
            <br />
            <Form.Group>
              <Form.Label>Selecione uma foto</Form.Label>
              <Form.Control
                required
                name='image'
                id='image'
                type='file'
                accept='image/*'
                // label="Selecione um arquivo"
                //onChange={changeHandler}
                onChange={onSelectFile}
              />
              <Form.Control.Feedback type='invalid'>Selecione um arquivo</Form.Control.Feedback>
            </Form.Group>
            <br />
            <div>
              {/* <label htmlFor="image">
                <a style={{ cursor: 'pointer' }}>
                  {selectedFile && <img src={preview} style={{ width: '50%' }} />}
                </a>
              </label> */}
              {/* <input type="file" accept="image/*" onChange={onSelectFile} /> */}
              
              <div className='row g-5 gx-xxl-12'>
                  <div className='col-6'>
                      {!!imgSrc && (
                      <div>
                        <div>Posicione a foto</div>
                        <ReactCrop
                          crop={crop}
                          onChange={(_, percentCrop) => setCrop(percentCrop)}
                          onComplete={(c) => setCompletedCrop(c)}
                          aspect={aspect}
                          maxHeight={350}
                          maxWidth={350}

                        >
                          <img
                            ref={imgRef}
                            alt="Crop me"
                            src={imgSrc}
                            style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                            onLoad={onImageLoad}
                          />
                        </ReactCrop>
                      </div>
                    )}

                  </div>
                  <div className='col-3'>

                  <div>
                    {!!completedCrop && !!imgSrc && (
                      <div>
                        <div>Prévia</div>
                        <canvas
                          ref={previewCanvasRef}
                          style={{
                            border: '1px solid black',
                            objectFit: 'contain',
                            width: completedCrop.width,
                            height: completedCrop.height,
                          }}
                        />
                      </div>
                    )}
                  </div>


                  </div>
              </div>
              


              
              






            </div>
            <br /><br />
          </div>
          <div className='col-xxl-6'>
            <h5>Dados do Hemocentro</h5>
            {noBloodcenter && <div><Form.Group controlId='fromName'>
              <Form.Label>Nome do Hemocentro</Form.Label>
              <Form.Control placeholder='' value={bcname} onChange={(e: any) => setBcname(e.target.value)} required />
              <Form.Control.Feedback type='invalid'>Por favor informe o nome do hemocentro</Form.Control.Feedback>
            </Form.Group>
              <br />
              <Form.Group controlId='fromName'>
                <Form.Label>Endereço</Form.Label>
                <Form.Control placeholder='' value={bcaddress} onChange={(e: any) => setBcaddress(e.target.value)} required />
                <Form.Control.Feedback type='invalid'>Por favor informe o endereço do hemocentro</Form.Control.Feedback>
              </Form.Group>
              <br />
              <Form.Group controlId="formBasicSelect">
                <Form.Label>Estado</Form.Label>


                {state.loading ? <Loading /> :
                  <Form.Control
                    as="select"
                    value={bcstate}
                    onChange={(e: any) => setState(e.target.value)}
                    required
                  >
                    <option value="" selected disabled hidden>Selecione um estado</option>
                    {state.data.map(st => {
                      return <option value={'' + st.id}>{st.name}</option>
                    })}
                  </Form.Control>
                }
              </Form.Group>

              <br />


              <Form.Group controlId="formBasicSelect">
                <Form.Label>Cidade</Form.Label>
                {city.loading ? <Loading /> :
                  <Form.Control
                    required
                    disabled={!bcstate}
                    as="select"
                    value={bccity}
                    onChange={(e: any) => setBccity(e.target.value)}
                  >
                    <option value="" selected disabled hidden>Selecione uma cidade</option>
                    {city.data.map((ct) => {
                      return <option value={'' + ct.id}>{ct.name}</option>
                    })}
                  </Form.Control>
                }
              </Form.Group></div>}


            {!noBloodcenter && <Form.Group controlId="formBasicSelect">
              <Form.Label>Selecione o hemocentro</Form.Label>
              {bc.loading ? <Loading /> :
                <Form.Control
                  required
                  as="select"
                  value={bloodCenter}
                  onChange={(e: any) => setBloodCenter(e.target.value)}
                >
                  <option value="" selected disabled hidden>Selecione um hemocentro</option>
                  {separated.map((bcitem: any) => {
                    return bcitem.disabled ?
                      <option style={{ backgroundColor: '#EAEAEA', fontSize: 19 }} value={bcitem.id} disabled={bcitem.disabled}>{bcitem.name}</option>
                      :
                      <option value={bcitem.id} disabled={bcitem.disabled}>{bcitem.name} - {bcitem.stateParent?.name}</option>
                  })}

                  {/* {bc.data.map((bcitem:Bloodcenter) => {
                    return <option value={bcitem.id}>{bcitem.name} - {bcitem.stateParent?.name}</option>
                  })} */}
                </Form.Control>
              }
            </Form.Group>}
            <br />
            <a href='#' onClick={() => setNoBloodcenter(!noBloodcenter)}>{noBloodcenter ? 'Selecionar um hemocentro na lista' : 'O hemocentro não está na lista? Clique aqui'}</a>
            <br />
            <br />
            <Form.Group controlId='fromName'>
              <Form.Label>Informações adicionais</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                placeholder=''
                value={description}
                onChange={(e: any) => setDescription(e.target.value)}
              />
              {/* <Form.Control.Feedback type='invalid'>Por favor informe o nome</Form.Control.Feedback> */}
            </Form.Group>
            <br />


            {/* <ReactSectionList
              keyExtractor={keyExtractor}
              renderSectionHeader={renderSectionHeader}
              renderItem={renderItem}
              sections={separated}
              mode="dropdown"
            /> */}




            <br />
          </div>
        </div>




        <Button size='sm' variant='primary' type='submit' className='float-right'>
          Salvar
        </Button>
      </Form>
      {/* Deixar o button fora do form.. */}
    </>
  )
}
export default Create
