/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useState, useEffect } from 'react'
import { Form } from 'react-bootstrap-v5'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { ApplicationState } from '../../../store'
import { bloodSelectedRequest, loadCampaignsCompatibilityRequest } from '../../../store/ducks/campaign/actions'
// import { loadCampaignsCompatibilityRequest } from '../../../store/ducks/campaign/actions'

const doaPara = (bloodtype: string) => {
  switch (bloodtype) {
    case '1':
      return "A+, A-, B+, B-, AB+, AB-, O+, O-" //O-
    case '2':
      return "A+, B+, AB+, AB-" //O+
    case '3':
      return "A+, A-, AB+, AB-" //A-
    case '4':
      return "A+, AB+" //A+
    case '5':
      return "B+, B-, AB+, AB-" //B-
    case '6':
      return "B+, AB+" //B+
    case '7':
      return "AB+, AB-" //AB-
    case '8':
      return "AB+" //AB+
    default:
      return "Invalido"
  }
}

const recebeDe = (bloodtype: string) => {
  switch (bloodtype) {
    case '1':
      return "O-" //O-
    case '2':
      return "O+, O-" //O+
    case '3':
      return "A-, O-" //A-
    case '4':
      return "A+, A-, O+, O-" //A+
    case '5':
      return "B-, O-" //B-
    case '6':
      return "B+, B-, O+, O-" //B+
    case '7':
      return "A-, B-, AB-, O-" //AB-
    case '8':
      return "A+, A-, B+, B-, AB+, AB-, O+, O-" //AB+
    default:
      return "Invalido"
  }
}

const doaParaInt = (bloodtype: string) => {
  switch (bloodtype) {
    case '1':
      return [1,2,3,4,5,6,7,8] //O-
    case '2':
      return [4,6,8,7] //O+
    case '3':
      return [4,3,8,7] //A-
    case '4':
      return [4,8] //A+
    case '5':
      return [6,5,8,7] //B-
    case '6':
      return [6,8] //B+
    case '7':
      return [8,7] //AB-
    case '8':
      return [8] //AB+
    default:
      return [1,2,3,4,5,6,7,8] //Retorna todos
  }
}



const bloodtypetext = (bloodtype: string) => {
  switch (bloodtype) {
    case '1':
      return "O-"
    case '2':
      return "O+"
    case '3':
      return "A-"
    case '4':
      return "A+"
    case '5':
      return "B-"
    case '6':
      return "B+"
    case '7':
      return "AB-"
    case '8':
      return "AB+"
    default:
      return "Invalido"
  }
}


const CompatibilityWidget: FC<React.PropsWithChildren<unknown>> = () => {
  const [validated, setValidated] = useState(false)
  const [bloodtype, setBloodtype] = useState('')
  const dispatch = useDispatch()

  const campaign = useSelector((state: ApplicationState) => state.campaign)
  console.log("CAMPAIGN", campaign)

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    const form = event.currentTarget
    event.preventDefault()
    if (form.checkValidity() === false) {
      event.stopPropagation()
    }
    setValidated(true)
    console.log("Create campaign")
  }

  const changeBloodType = (blood:any) => {
    setBloodtype(blood)
    console.log("DOAPARAINT", doaParaInt(blood))
    dispatch(loadCampaignsCompatibilityRequest(doaParaInt(blood)))
    dispatch(bloodSelectedRequest(blood))
  }

  useEffect(() => {
    // console.log("############ Loading component hey...", { module_id, class_id, me })
    console.log("oi")

    dispatch(loadCampaignsCompatibilityRequest(doaParaInt(campaign.bloodSelected!)))
    //console.log("xxxx", doaParaInt('1'))
  }, [])

  return (
    <div style={{backgroundColor:'#E4E4F1', padding: 20, borderRadius:5, marginBottom: 25}}>
      <Form validated={validated} onSubmit={handleSubmit}>
      
          <Form.Group controlId="formBasicSelect">
            <Form.Label>Compatibilidade sanguínea</Form.Label>
            <Form.Control
              required
              as="select"
              //value={bloodtype}
              onChange={(e:any) => changeBloodType(e.target.value)}
            >
              <option value="" selected={campaign.bloodSelected! === ""}>Selecione um tipo sanguíneo</option>
              <option value="1" selected={campaign.bloodSelected! === "1"}>O-</option>
              <option value="2" selected={campaign.bloodSelected! === "2"}>O+</option>
              <option value="3" selected={campaign.bloodSelected! === "3"}>A-</option>
              <option value="4" selected={campaign.bloodSelected! === "4"}>A+</option>
              <option value="5" selected={campaign.bloodSelected! === "5"}>B-</option>
              <option value="6" selected={campaign.bloodSelected! === "6"}>B+</option>
              <option value="7" selected={campaign.bloodSelected! === "7"}>AB-</option>
              <option value="8" selected={campaign.bloodSelected! === "8"}>AB+</option>
            </Form.Control>
          </Form.Group>
          <br/>
        </Form>
        {bloodtype ? 
        <div className='text-center'>
          Você pode doar sangue para: <b>{doaPara(bloodtype)}.</b>
          <br/>
          Você pode receber sangue de: <b>{recebeDe(bloodtype)}.</b>
          <br/><br/>
          <b>Seu sangue pode salvar vidas. Doe sangue! </b>
          <br/> <br/> 
          <Link className='btn btn-primary' to={'/hemocentros'}>Procure um hemocentro mais perto de você.</Link>
        </div>
        :''
        }

    </div>
  )
}
export {CompatibilityWidget}
