// import { ComponentAvailable } from '../componentavailable/types';
import {Extras} from '../extras/types'
/**
 * Action types
 */
export enum ComponentTypes {
  //Load component and children
  LOAD_COMPONENT_REQUEST = '@component/LOAD_COMPONENT_REQUEST',
  LOAD_COMPONENT_SUCCESS = '@component/LOAD_COMPONENT_SUCCESS',
  LOAD_COMPONENT_FAILURE = '@component/LOAD_COMPONENT_FAILURE',

  //Create component
  CREATE_COMPONENT_REQUEST = '@component/CREATE_COMPONENT_REQUEST',
  CREATE_COMPONENT_SUCCESS = '@component/CREATE_COMPONENT_SUCCESS',
  CREATE_COMPONENT_FAILURE = '@component/CREATE_COMPONENT_FAILURE',

  //Update component
  UPDATE_COMPONENT_REQUEST = '@component/UPDATE_COMPONENT_REQUEST',
  UPDATE_COMPONENT_SUCCESS = '@component/UPDATE_COMPONENT_SUCCESS',
  UPDATE_COMPONENT_FAILURE = '@component/UPDATE_COMPONENT_FAILURE',

  //Delete component
  DELETE_COMPONENT_REQUEST = '@component/DELETE_COMPONENT_REQUEST',
  DELETE_COMPONENT_SUCCESS = '@component/DELETE_COMPONENT_SUCCESS',
  DELETE_COMPONENT_FAILURE = '@component/DELETE_COMPONENT_FAILURE',

  //Delete aulaconcluida
  SEARCH_REQUEST = '@component/SEARCH_REQUEST',
  SEARCH_SUCCESS = '@component/SEARCH_SUCCESS',
  SEARCH_FAILURE = '@component/SEARCH_FAILURE',
}

/**
 * Data types
 */
// User Imported from Me
export interface Component {
  id?: number
  component_id?: number
  name?: string
  description?: string
  created_at?: string
  status?: number | boolean
  order?: string
  children?: Component[] | undefined
  extras?: Extras[]
  parent?: Component | undefined
  componentavailable?: any
}
/**
 * State type
 */
export interface ComponentState {
  readonly search: any[]
  readonly data: Component
  readonly loading: boolean
  readonly error: boolean
}
