/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
// import * as auth from '../reduxOLD/AuthRedux'
// import {register} from '../reduxOLD/AuthCRUD'
import {Link} from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../../../../store'

export function ErrorLogin() {
  const me = useSelector((state: ApplicationState) => state.me)
  return (

      <div className='mb-10 text-center'>
        {/* begin::Title */}
        <h1 className='text-dark mb-3'>Ocorreu um erro</h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className='text-gray-400 fw-bold fs-4'>
          {me.message === 'User already exists'? 'Este e-mail já está cadastrado em nosso sistema, fique tranquilo(a) porque você pode recuperar sua senha. Clique no link abaixo.': me.message}
          <br/><br/>
          <Link to='/auth/forgot-password' className='link-primary fw-bolder' style={{marginLeft: '5px'}}>
            Recuperar a senha
          </Link>
        </div>
        {/* end::Link */}
      </div>
      
  )
}
