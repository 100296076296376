/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {
  KTSVG,
  // toAbsoluteUrl
} from '../../design/helpers'
import {ComponentState} from '../../../store/ducks/component/types'
// import { Link } from "react-router-dom";
import {useIntl} from 'react-intl'

// const MOMENT= require( 'moment' );

type Props = {
  className: string
  comp: ComponentState
  id: string
}

// let today = MOMENT().format( 'YYYY-MM-DD HH:mm:ss.000' );
// const add = (accumulator: number, a: number) => {
//   return accumulator + a;
// }

// const withLinkToModule = (text: any, id: any, module_id: any, isAvailable: boolean) => {
//   if (isAvailable) {
//     return (
//       <Link to={'/class/' + id + '/' + module_id} style={{display:'flex' }} className='fs-6 text-gray-800 text-hover-primary fw-bolder'>
//         {text}
//       </Link>
//     )
//   } else {
//     return (<div style={{ color: '#a97a7a'}}>{text}</div>);
//   }
// }

const PageTextWidget: React.FC<React.PropsWithChildren<Props>> = ({className, comp, id}) => {
  // console.log("MODULOS", comp)
  const intl = useIntl()
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>
            {/* {intl.formatMessage({id: 'MENU.AGENDA'})} */}
            {comp.data.name}
          </span>
          <span className='text-muted mt-1 fw-bold fs-7'>{comp.data.description}</span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        <div className='tab-content'>
          {/* begin::Tap pane */}
          <div className='tab-pane fade show active' id='kt_table_widget_5_tab_1'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='border-0'>
                    {/* <th className='p-0 w-40px'></th> */}
                    <th className='p-0 min-w-150px'></th>
                    <th className='p-0 min-w-140px'></th>
                    <th className='p-0 min-w-110px'></th>
                    <th className='p-0 min-w-50px'></th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  <tr>
                    <td>
                      {comp.data.extras?.map(extra => {
                        return <div dangerouslySetInnerHTML={{__html: extra.value_extra!}}></div>
                      })}
                    </td>
                  </tr>
                </tbody>
                {/* end::Table body */}
              </table>
            </div>
            {/* end::Table */}
          </div>
          {/* end::Tap pane */}
        </div>
      </div>
      {/* end::Body */}
    </div>
  )
}

export {PageTextWidget}
