import {all, takeLatest} from 'redux-saga/effects'

import {UsersTypes} from './users/types'
import {
  loadUsers,
  // findUserId,
  searchUser,
  createUser,
  updateUser,
  deleteUser,
  loadUser,
} from './users/sagas'

import {MeTypes} from './me/types'
import {loginUser, createMe, updateMe, deleteMe, recoveryUser, loadMe, confirmMe} from './me/sagas'

import {ComponentTypes} from './component/types'
import {
  loadComponent,
  createComponent,
  updateComponent,
  deleteComponent,
  searchComponent,
  // uploadExtra
} from './component/sagas'
import { CampaignTypes } from './campaign/types'
import { bloodSelect, createCampaign, deleteCampaign, loadCampaign, loadCampaigns, loadCampaignsCompatibility, loadMyCampaigns, searchCampaigns, updateCampaign } from './campaign/sagas'
import { StateTypes } from './state/types'
import { loadState } from './state/sagas'
import { CityTypes } from './city/types'
import { loadCity } from './city/sagas'
import { ContactsTypes } from './contact/types'
import { createcontact, loadAllcontacts, loadcontacts, updatecontact} from './contact/sagas'
import { loadBloodcenter, loadBloodcenters, searchBloodcenter } from './bloodcenter/sagas'
import { BloodcenterTypes } from './bloodcenter/types'


export default function* rootSaga() {
  yield all([
    //takeLatest(UsersTypes.CREATE_USER_REQUEST, createUser),

    //Me
    takeLatest(MeTypes.LOGIN_USER_REQUEST, loginUser),
    takeLatest(MeTypes.UPDATE_USER_REQUEST, updateMe),
    takeLatest(MeTypes.CREATE_USER_REQUEST, createMe),
    takeLatest(MeTypes.DELETE_USER_REQUEST, deleteMe),
    takeLatest(MeTypes.RECOVERY_USER_REQUEST, recoveryUser),
    takeLatest(MeTypes.LOAD_ME_REQUEST, loadMe),
    takeLatest(MeTypes.CONFIRM_USER_REQUEST, confirmMe),

    //Users
    takeLatest(UsersTypes.LOAD_USERS_REQUEST, loadUsers),
    takeLatest(UsersTypes.LOAD_USER_REQUEST, loadUser),
    takeLatest(UsersTypes.UPDATE_USER_REQUEST, updateUser),
    takeLatest(UsersTypes.CREATE_USER_REQUEST, createUser),
    takeLatest(UsersTypes.DELETE_USER_REQUEST, deleteUser),
    takeLatest(UsersTypes.SEARCH_USERS_REQUEST, searchUser),

    //Components
    takeLatest(ComponentTypes.LOAD_COMPONENT_REQUEST, loadComponent),
    takeLatest(ComponentTypes.SEARCH_REQUEST, searchComponent),
    takeLatest(ComponentTypes.CREATE_COMPONENT_REQUEST, createComponent),
    takeLatest(ComponentTypes.UPDATE_COMPONENT_REQUEST, updateComponent),
    takeLatest(ComponentTypes.DELETE_COMPONENT_REQUEST, deleteComponent),

    // takeLatest(ComponentTypes.UPLOAD_EXTRA_REQUEST,uploadExtra),
    takeLatest(CampaignTypes.LOAD_CAMPAIGN_REQUEST, loadCampaign),
    takeLatest(CampaignTypes.LOAD_CAMPAIGNSCOMPATIBILITY_REQUEST, loadCampaignsCompatibility),
    //takeLatest(CampaignTypes.BLOOD_SELECTED, bloodSelect),
    takeLatest(CampaignTypes.SEARCH_CAMPAIGN_REQUEST, searchCampaigns),
    takeLatest(CampaignTypes.LOAD_MYCAMPAIGNS_REQUEST, loadMyCampaigns),
    takeLatest(CampaignTypes.LOAD_CAMPAIGNS_REQUEST, loadCampaigns),
    takeLatest(CampaignTypes.CREATE_CAMPAIGN_REQUEST, createCampaign),
    takeLatest(CampaignTypes.UPDATE_CAMPAIGN_REQUEST, updateCampaign),
    takeLatest(CampaignTypes.DELETE_CAMPAIGN_REQUEST, deleteCampaign),


    takeLatest(StateTypes.LOAD_STATES_REQUEST, loadState),
    takeLatest(CityTypes.LOAD_CITIES_REQUEST, loadCity),

    takeLatest(ContactsTypes.LOAD_ALLCONTACT_REQUEST, loadAllcontacts),
    takeLatest(ContactsTypes.LOAD_CONTACT_REQUEST, loadcontacts),
    takeLatest(ContactsTypes.CREATE_CONTACT_REQUEST, createcontact),
    takeLatest(ContactsTypes.UPDATE_CONTACT_REQUEST, updatecontact),

    takeLatest(BloodcenterTypes.LOAD_BLOODCENTERS_REQUEST, loadBloodcenters),
    takeLatest(BloodcenterTypes.LOAD_BLOODCENTER_REQUEST, loadBloodcenter),
    takeLatest(BloodcenterTypes.SEARCH_BLOODCENTER_REQUEST, searchBloodcenter),
    // takeLatest(BloodcenterTypes.CREATE_BLOODCENTER_REQUEST, createBloodcenter),
    // takeLatest(BloodcenterTypes.UPDATE_BLOODCENTER_REQUEST, updateBloodcenter),
    // takeLatest(BloodcenterTypes.DELETE_BLOODCENTER_REQUEST, deleteBloodcenter),
    

    // takeLatest(ExtraTypes.LOAD_EXTRAS_REQUEST,loadExtras),
    // takeLatest(ExtraTypes.CREATE_EXTRA_REQUEST,createExtra),
    // takeLatest(ExtraTypes.UPDATE_EXTRA_REQUEST,updateExtra),
    // takeLatest(ExtraTypes.DELETE_EXTRA_REQUEST,deleteExtra),
  ])
  // console.log('mounting saga...')
}
