import {Reducer} from 'redux'
import {BloodcenterState, BloodcenterTypes} from './types'

const INITIAL_STATE: BloodcenterState = {
  data: [],
  error: false,
  loading: false,
  
  bloodcenter: {},
  errorBloodcenter: false,
  loadingBloodcenter: false,
  
  search: [],
  loadingSearch: false,
  errorSearch: false,


}

const reducer: Reducer<BloodcenterState> = (state = INITIAL_STATE, action) => {
  console.log('################################Reducer inside Component: ' + action.type + ':', action.payload)
  switch (action.type) {
    //all users:
    case BloodcenterTypes.LOAD_BLOODCENTERS_REQUEST:
      return {...state, loading: true}
    case BloodcenterTypes.LOAD_BLOODCENTERS_SUCCESS:
      return {...state, loading: false, error: false, data: action.payload.data}
    case BloodcenterTypes.LOAD_BLOODCENTERS_FAILURE:
      return {...state, loading: false, error: true, data: []}

    case BloodcenterTypes.SEARCH_BLOODCENTER_REQUEST:
      return {...state, loadingSearch: true}
    case BloodcenterTypes.SEARCH_BLOODCENTER_SUCCESS:
      return {...state, loadingSearch: false, errorSearch: false, search: action.payload.data}
    case BloodcenterTypes.SEARCH_BLOODCENTER_FAILURE:
      return {...state, loadingSearch: false, errorSearch: true, search: []}

    case BloodcenterTypes.LOAD_BLOODCENTER_REQUEST:
      return {...state, loadingBloodcenter: true}
    case BloodcenterTypes.LOAD_BLOODCENTER_SUCCESS:
      return {...state, loadingBloodcenter: false, errorBloodcenter: false, bloodcenter: action.payload.data}
    case BloodcenterTypes.LOAD_BLOODCENTER_FAILURE:
      return {...state, loadingBloodcenter: false, errorBloodcenter: true, bloodcenter: {}}

    //Create
    case BloodcenterTypes.CREATE_BLOODCENTER_REQUEST:
      return {...state}
    case BloodcenterTypes.CREATE_BLOODCENTER_SUCCESS:
      return {
        ...state, 
        loading: false, 
        error: false, 
        data: [action.payload.data, ...state.data],
      
      }
    case BloodcenterTypes.CREATE_BLOODCENTER_FAILURE:
      return {...state, loading: false, error: true}

    //Update
    case BloodcenterTypes.UPDATE_BLOODCENTER_REQUEST:
      return {...state, loading: true}
    case BloodcenterTypes.UPDATE_BLOODCENTER_SUCCESS:
      return {
        ...state, 
        loading: false, 
        error: false,
        data: state.data.map((child) =>
          child.id === action.payload.data.id ? action.payload.data : child
        )
    }
    case BloodcenterTypes.UPDATE_BLOODCENTER_FAILURE:
      return {...state, loading: false, error: true}

    //Delete
    case BloodcenterTypes.DELETE_BLOODCENTER_REQUEST:
      return {...state}
    case BloodcenterTypes.DELETE_BLOODCENTER_SUCCESS:
      return {
        ...state, 
        loading: false, 
        error: false, 
        data: state.data.filter((item) => item.id !== action.payload.data),
      }
    case BloodcenterTypes.DELETE_BLOODCENTER_FAILURE:
      return {...state, loading: false, error: true, data: []}
    default:
      return state
  }
}

export default reducer
