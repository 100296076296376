import React, { FC, useEffect } from 'react'
import { PageTitle } from '../../../design/layout/core'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../../../../store'
import { useDispatch } from 'react-redux'
import { loadCampaignRequest } from '../../../../store/ducks/campaign/actions'
import { useParams } from 'react-router-dom'
import Loading from '../../../design/loading'
import { CampaignState } from '../../../../store/ducks/campaign/types'
import {
  EmailShareButton,
  FacebookShareButton,
  FacebookShareCount,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,
  TwitterIcon,
  FacebookIcon,
  LinkedinIcon,
  PinterestIcon,
  TelegramIcon,
  EmailIcon,
  WhatsappIcon,

} from "react-share";
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet-async';

const MOMENT = require('moment')
type ParamTypes = {
  id: string
}

type Props = {
  campaign: CampaignState
}
const doaPara = (bloodtype: string) => {
  switch (bloodtype) {
    case '1':
      return "A+, A-, B+, B-, AB+, AB-, O+, O-" //O-
    case '2':
      return "A+, B+, AB+, AB-" //O+
    case '3':
      return "A+, A-, AB+, AB-" //A-
    case '4':
      return "A+, AB+" //A+
    case '5':
      return "B+, B-, AB+, AB-" //B-
    case '6':
      return "B+, AB+" //B+
    case '7':
      return "AB+, AB-" //AB-
    case '8':
      return "AB+" //AB+
    default:
      return "Invalido"
  }
}

const recebeDe = (bloodtype: string) => {
  switch (bloodtype) {
    case '1':
      return "O-" //O-
    case '2':
      return "O+, O-" //O+
    case '3':
      return "A-, O-" //A-
    case '4':
      return "A+, A-, O+, O-" //A+
    case '5':
      return "B-, O-" //B-
    case '6':
      return "B+, B-, O+, O-" //B+
    case '7':
      return "A-, B-, AB-, O-" //AB-
    case '8':
      return "A+, A-, B+, B-, AB+, AB-, O+, O-" //AB+
    default:
      return "Invalido"
  }
}

const bloodtypetext = (bloodtype: string) => {
  switch (bloodtype) {
    case '1':
      return "O-"
    case '2':
      return "O+"
    case '3':
      return "A-"
    case '4':
      return "A+"
    case '5':
      return "B-"
    case '6':
      return "B+"
    case '7':
      return "AB-"
    case '8':
      return "AB+"
    default:
      return "Invalido"
  }
}
const ViewCampaignPage: FC<React.PropsWithChildren<Props>> = ({ campaign }) => {
  let created_at_text = MOMENT(Number(campaign.campaign.created_at!) * 1000) //.format('DD/MM/YYYY HH:mm')
  const title = `Ajude a salvar ${campaign.campaign.name} | Salve Mais Um`;
  const url = window.location.href;
  const gmapsurl = 'https://www.google.com.br/maps/place/' + campaign.campaign.bcaddress + ' ' + campaign.campaign.cityParent?.name + ' ' + campaign.campaign.stateParent?.state
  const gmurl = gmapsurl.replaceAll(' ', '+')
  const me = useSelector((state: ApplicationState) => state.me)
  console.log("url", gmurl)

  const iconSize = 48;
  return (
    <>
      {/* begin::Row */}

      <div className='row gy-5 g-xl-8'>
        <div className='col-xxl-3'>

          <div className={`card card-xxl-stretch mb-5 mb-xxl-6`}>
            <img
              src={'../../files/' + campaign.campaign.image}
              style={{ width: '100%' }}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = "https://salvemaisum.com.br/media/guestuser.jpg";
              }}
            />
            {/* begin::Header */}
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3'>
                  {campaign.campaign.name}
                </span>
              </h3>
            </div>
            <div style={{ paddingLeft: '2.25rem', paddingRight: '2.25rem' }}>
              {/* {me.me.id === campaign.campaign?.parentUser?.user_id && <div><Link className='btn btn-sm btn-primary' to={'/edit'}>Editar campanha</Link><br/><br/></div>} */}
              Tipo sanguíneo: {bloodtypetext(campaign.campaign.bloodtype!)}
              <br />
              Pode receber doação de: {recebeDe(campaign.campaign.bloodtype!)}
              <br />
              Campanha criada em: {created_at_text.format('DD/MM/YYYY HH:mm')}
              <br /><br />
            </div>
          </div>
        </div>

        <div className='col-xxl-9'>

          <div className={`card card-xxl-stretch mb-5 mb-xxl-6`}>
            {/* begin::Header */}
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>
                  Onde você pode doar sangue? <br />
                </span>
              </h3>

            </div>
            <div className='card-body'>
              {campaign.campaign.bcname ?
                <div>
                  <h3>{campaign.campaign.bcname}</h3>
                  {campaign.campaign.bcaddress}
                  <br />
                  {campaign.campaign.cityParent?.name}/{campaign.campaign.stateParent?.state}
                  <br />
                </div>
                : ''}


              {campaign.campaign.bcParent &&
                <div>
                  <Link to={'/hemocentro/' + campaign.campaign.bcParent.id}>
                    <b>{campaign.campaign.bcParent.name}</b>
                    <br />
                    <img
                      src={campaign.campaign.bcParent?.image?.includes('https://') ? campaign.campaign.bcParent?.image : 'https://salvemaisum.com.br/files/' + campaign.campaign.bcParent?.image}
                      style={{ borderRadius: 5, width: 100, paddingTop: 8, paddingBottom: 8 }}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = "https://salvemaisum.com.br/media/guestuser.jpg";
                      }}
                    />
                    <br />
                    {campaign.campaign.bcParent.address}
                  </Link>
                </div>}

                {campaign.campaign.description &&
                <div>
                  <br/><br/>
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bolder fs-3 mb-1'>
                      Informações adicionais <br />
                    </span>
                  </h3>
                  
                {campaign.campaign.description}  
                </div>}
              <br /><br />
              <h3>Quem iniciou essa campanha?</h3>


              <br />
              <Link to={'/profile/' + campaign.campaign.parentUser?.user_id}>

                <img
                  src={campaign.campaign.parentUser?.image?.includes('https://') ? campaign.campaign.parentUser?.image : '../../files/' + campaign.campaign.parentUser?.image}
                  style={{ borderRadius: 120, width: 50 }}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = "https://salvemaisum.com.br/media/guestuser.jpg";
                  }}
                />
                <br />
                {campaign.campaign.parentUser?.name}
              </Link>
              <br /><br />
              <h3>Compartilhe essa campanha</h3>

              <div className="ShareButtons">
                <EmailShareButton url={url} title={title} className="share-button">
                  <EmailIcon size={iconSize} round />
                </EmailShareButton>
                <TwitterShareButton url={url} title={title} className="share-button">
                  <TwitterIcon size={iconSize} round />
                </TwitterShareButton>
                {/* <FacebookShareButton url={url} hashtag={`#salvemaisum`} quote={title} className="share-button"> */}
                <FacebookShareButton url={url} hashtag={`#salvemaisum`} quote={title} className="share-button">
                  <FacebookIcon size={iconSize} round />
                </FacebookShareButton>
                <FacebookShareCount url={url}></FacebookShareCount>
                {/* <GooglePlusShareButton url={url} className="share-button">
                  <GooglePlusIcon size={iconSize} round /></Fade>
                </GooglePlusShareButton> */}
                <LinkedinShareButton url={url} title={title} className="share-button">
                  <LinkedinIcon size={iconSize} round />
                </LinkedinShareButton>
                <PinterestShareButton url={url} media="/favicon.ico" className="share-button">
                  <PinterestIcon size={iconSize} round />
                </PinterestShareButton>
                <TelegramShareButton url={url} title={title} className="share-button">
                  <TelegramIcon size={iconSize} round />
                </TelegramShareButton>
                <WhatsappShareButton url={url} title={title} className="share-button">
                  <WhatsappIcon size={iconSize} round />
                </WhatsappShareButton>

              </div>
              <br />
            </div>
          </div>
          <br />
        </div>
      </div>





      {/* end::Row */}
    </>
  )
}

const ViewCampaign: FC<React.PropsWithChildren<unknown>> = () => {
  const intl = useIntl()
  const me = useSelector((state: ApplicationState) => state.me)
  //const component = useSelector((state: ApplicationState) => state.component)
  const campaign = useSelector((state: ApplicationState) => state.campaign)
  const dispatch = useDispatch()
  let { id } = useParams<ParamTypes>()

  console.log("ME AQUI", me)
  console.log("campaigns", campaign)


  useEffect(() => {
    // console.log("############ Loading component hey...", { module_id, class_id, me })
    // console.log("ID", id)
    // console.log("camp id", campaign.campaign.id)
    // console.log("dispatch?", +campaign.campaign.id! !== +id!)
    // if(campaign.campaign.name)
    //   document.title = 'Ajude a salvar '+ campaign.campaign.name;
    if (+campaign.campaign.id! !== +id!)
      dispatch(loadCampaignRequest(+id!))

  }, [campaign.campaign])

  if (campaign.loadingCampaign) return <Loading />
  if (!campaign.campaign.name) return <Loading />

  return (
    <>
      {/* {campaign.campaign.name} */}
      <Helmet>
        <title>Ajude a salvar {campaign.campaign.name}</title>
        <meta name="description" content={'Ajude a salvar ' + campaign.campaign.name} />
        <meta name="og:description" content={'Ajude a salvar ' + campaign.campaign.name} />
        <meta name="og:title" content={'Ajude a salvar ' + campaign.campaign.name} />
        <meta name="og:image" content={campaign.campaign.image?.includes('https://') ? campaign.campaign.image : 'https://salvemaisum.com.br/files/' + campaign.campaign.image} />
        <meta name="og:url" content={'https://salvemaisum.com.br/campanha/' + campaign.campaign.id} />
        <meta name="site_name" content={'Salve mais um'} />

        <meta name="twitter:description" content={'Ajude a salvar ' + campaign.campaign.name} />
        <meta name="twitter:title" content={'Ajude a salvar ' + campaign.campaign.name} />
        <meta name="twitter:image" content={campaign.campaign.image?.includes('https://') ? campaign.campaign.image : 'https://salvemaisum.com.br/files/' + campaign.campaign.image} />
        <meta name="twitter:url" content={'https://salvemaisum.com.br/campanha/' + campaign.campaign.id} />

      </Helmet>
      {/* <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.SALVEMAISUM'})}</PageTitle> */}
      <PageTitle breadcrumbs={[]}>{'Ajude a salvar ' + campaign.campaign.name}</PageTitle>
      <ViewCampaignPage campaign={campaign} />
    </>
  )
}
export { ViewCampaign }
