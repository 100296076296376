import {call, put} from 'redux-saga/effects'
import api from '../../../services/api'

import {
  loadCampaignsFailure,
  loadCampaignsRequest,
  loadCampaignsSuccess,

  loadMyCampaignsFailure,
  loadMyCampaignsRequest,
  loadMyCampaignsSuccess,

  createCampaignFailure,
  createCampaignRequest,
  createCampaignSuccess,

  updateCampaignFailure,
  updateCampaignRequest,
  updateCampaignSuccess,

  deleteCampaignFailure,
  deleteCampaignRequest,
  deleteCampaignSuccess,

  loadCampaignRequest,
  loadCampaignSuccess,
  loadCampaignFailure,
  searchCampaignsRequest,
  searchCampaignsSuccess,
  searchCampaignsFailure,
  loadCampaignsCompatibilityRequest,
  loadCampaignsCompatibilitySuccess,
  loadCampaignsCompatibilityFailure,
  bloodSelectedRequest


  // loadCourseRequest,
} from './actions'

import {Campaign} from './types'

// import { loadExtrasSuccess } from '../extras/actions'
import {Extras} from '../extras/types'
// import axios from 'axios';

//Load Campaign
export function* loadCampaigns(payload: ReturnType<typeof loadCampaignsRequest>) {
  try {
    put(loadCampaignsRequest(payload.payload.page, payload.payload.take))
    const response: Campaign = yield call(api.get, `campaigns/${payload.payload.page}/${payload.payload.take}`)
    yield put(loadCampaignsSuccess(response))
  } catch (error) {
    yield put(loadCampaignsFailure())
  }
}

export function* loadCampaignsCompatibility(payload: ReturnType<typeof loadCampaignsCompatibilityRequest>) {
  try {
    // put(loadCampaignsCompatibilityRequest(payload.payload))
    console.log("PAYLOAD", payload.payload)
    console.log("PAYLOAD string", payload.payload.toString())
    const response: number = yield call(api.get, `searchcampaigncompatibility/${payload.payload.toString()}`)
    yield put(loadCampaignsCompatibilitySuccess(payload.payload, response))
  } catch (error) {
    yield put(loadCampaignsCompatibilityFailure())
  }
}

export function* bloodSelect(payload: ReturnType<typeof bloodSelectedRequest>) {
  try {
    yield put(bloodSelectedRequest(payload.payload))
  } catch (error) {
    console.log("error", error)
  }
}

//Load My Campaigns
export function* loadMyCampaigns(payload: ReturnType<typeof loadMyCampaignsRequest>) {
  try {
    put(loadMyCampaignsRequest(payload.payload.id))
    const response: Campaign = yield call(api.get, 'mycampaigns/' + payload.payload.id)
    yield put(loadMyCampaignsSuccess(response))
  } catch (error) {
    yield put(loadMyCampaignsFailure())
  }
}

//Search
export function* searchCampaigns(payload: ReturnType<typeof searchCampaignsRequest>) {
  try {
    put(searchCampaignsRequest(payload.payload.text))
    const response: Campaign = yield call(api.get, 'searchcampaign/' + payload.payload.text)
    yield put(searchCampaignsSuccess(response))
  } catch (error) {
    yield put(searchCampaignsFailure())
  }
}

//Load Campaign
export function* loadCampaign(payload: ReturnType<typeof loadCampaignRequest>) {
  try {
    put(loadCampaignRequest(payload.payload.id))
    const response: Campaign = yield call(api.get, 'campaign/' + payload.payload.id)
    yield put(loadCampaignSuccess(response))
  } catch (error) {
    yield put(loadCampaignFailure())
  }
}

//Create Campaign
export function* createCampaign(payload: ReturnType<typeof createCampaignRequest>) {
  try {
    const response: Campaign = yield call(api.post, 'campaign', payload.payload)
    yield put(createCampaignSuccess(response))
  } catch (error: any) {
    yield put(createCampaignFailure(error.response.data))
  }
}

//Update Campaign
export function* updateCampaign(payload: ReturnType<typeof updateCampaignRequest>) {
  try {
    put(updateCampaignRequest(payload.payload))
    const response: Campaign = yield call(api.post, 'campaign', payload.payload)
    yield put(updateCampaignSuccess(response))
  } catch (error) {
    yield put(updateCampaignFailure())
  }
}

//Delete Campaign
export function* deleteCampaign(payload: ReturnType<typeof deleteCampaignRequest>) {
  console.log("DELETANDO CAMPANHA", payload)
  try {
    const number: number = yield call(api.delete, 'campaign/' + payload.payload)
    console.log("NUMBER", number)
    yield put(deleteCampaignSuccess(number))
  } catch (error) {
    yield put(deleteCampaignFailure())
  }
}
