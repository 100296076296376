import {Reducer} from 'redux'
import {ComponentState, ComponentTypes} from './types'

const INITIAL_STATE: ComponentState = {
  data: {},
  search: [],
  error: false,
  loading: false,
}

const reducer: Reducer<ComponentState> = (state = INITIAL_STATE, action) => {
  // console.log('################################Reducer inside Component: ' + action.type + ':', action)
  switch (action.type) {
    //all users:
    case ComponentTypes.LOAD_COMPONENT_REQUEST:
      return {...state, loading: true}
    case ComponentTypes.LOAD_COMPONENT_SUCCESS:
      return {...state, loading: false, error: false, data: action.payload.data}
    case ComponentTypes.LOAD_COMPONENT_FAILURE:
      return {...state, loading: false, error: true, data: {}}

 

    //create component inside children:
    case ComponentTypes.CREATE_COMPONENT_REQUEST:
      return {...state}
    case ComponentTypes.CREATE_COMPONENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: {...state.data, children: state.data.children?.concat(action.payload.data)},
      }
    case ComponentTypes.CREATE_COMPONENT_FAILURE:
      return {...state, loading: false, error: true}

    //##update component:
    case ComponentTypes.UPDATE_COMPONENT_REQUEST:
      return {...state}
    case ComponentTypes.UPDATE_COMPONENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: {
          ...state.data,
          children: state.data.children?.map((child) =>
            child.id === action.payload.data.id ? action.payload.data : child
          ),
        },
      } //update data?
    case ComponentTypes.UPDATE_COMPONENT_FAILURE:
      return {...state, loading: false, error: true, data: {}}

    //delete user:
    case ComponentTypes.DELETE_COMPONENT_REQUEST:
      return {...state}
    case ComponentTypes.DELETE_COMPONENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: {
          ...state.data,
          children: state.data.children?.filter((item) => item.id !== action.payload.data),
        },
      }
    case ComponentTypes.DELETE_COMPONENT_FAILURE:
      return {...state, loading: false, error: true}


    default:
      return state
  }
}

export default reducer
