import React, {FC, useEffect} from 'react'
import {PageTitle} from '../../../design/layout/core'
import {useIntl} from 'react-intl'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../../../../store'
import { useDispatch } from 'react-redux'
import { loadCampaignRequest, loadMyCampaignsRequest } from '../../../../store/ducks/campaign/actions'
import { useParams } from 'react-router-dom'
import Loading from '../../../design/loading'
import { CampaignState } from '../../../../store/ducks/campaign/types'
import {
  EmailShareButton,
  FacebookShareButton,
  FacebookShareCount,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,
  TwitterIcon,
  FacebookIcon,
  LinkedinIcon,
  PinterestIcon,
  TelegramIcon,
  EmailIcon,

} from "react-share";
import { loadUserRequest } from '../../../../store/ducks/users/actions'
import { loadMeRequest } from '../../../../store/ducks/me/actions'
import { UsersState } from '../../../../store/ducks/users/types'
import { Link } from 'react-router-dom'

const MOMENT = require('moment')
type ParamTypes = {
  id: string
}

type Props = {
  users: UsersState
  campaigns: CampaignState
  id: number
}
const doaPara = (bloodtype: string) => {
  switch (bloodtype) {
    case '1':
      return "A+, A-, B+, B-, AB+, AB-, O+, O-" //O-
    case '2':
      return "A+, B+, AB+, AB-" //O+
    case '3':
      return "A+, A-, AB+, AB-" //A-
    case '4':
      return "A+, AB+" //A+
    case '5':
      return "B+, B-, AB+, AB-" //B-
    case '6':
      return "B+, AB+" //B+
    case '7':
      return "AB+, AB-" //AB-
    case '8':
      return "AB+" //AB+
    default:
      return "Invalido"
  }
}

const recebeDe = (bloodtype: string) => {
  switch (bloodtype) {
    case '1':
      return "O-" //O-
    case '2':
      return "O+, O-" //O+
    case '3':
      return "A-, O-" //A-
    case '4':
      return "A+, A-, O+, O-" //A+
    case '5':
      return "B-, O-" //B-
    case '6':
      return "B+, B-, O+, O-" //B+
    case '7':
      return "A-, B-, AB-, O-" //AB-
    case '8':
      return "A+, A-, B+, B-, AB+, AB-, O+, O-" //AB+
    default:
      return "Invalido"
  }
}

const bloodtypetext = (bloodtype: string) => {
  switch (bloodtype) {
    case '1':
      return "O-"
    case '2':
      return "O+"
    case '3':
      return "A-"
    case '4':
      return "A+"
    case '5':
      return "B-"
    case '6':
      return "B+"
    case '7':
      return "AB-"
    case '8':
      return "AB+"
    default:
      return "Invalido"
  }
}
const ViewProfilePage: FC<React.PropsWithChildren<Props>> = ({users, campaigns, id}) => {
  let created_at_text = MOMENT(Number(users.user.created_at!) * 1000) //.format('DD/MM/YYYY HH:mm')
  const title = `${document.title} | Salve Mais Um`;
  const me = useSelector((state: ApplicationState) => state.me)
  const iconSize = 48;
  const intl = useIntl()

  return (
    <>
      {/* begin::Row */}
      
      <div className='row gy-5 g-xl-8'>
          <div className='col-xxl-3'>
          
            <div className={`card card-xxl-stretch mb-5 mb-xxl-6`}>
              <img 
                //src={users.user.profile?.image} 
                //src={ image?.includes('https://') ? image : '../../files/' + image}
                src={ users.user.profile?.image?.includes('https://') ? users.user.profile?.image : '../../files/' + users.user.profile?.image}
                style={{width: '100%'}}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src="https://salvemaisum.com.br/media/guestuser.jpg";
                }}
               />
              {/* begin::Header */}
              <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bolder fs-3'>
                  {users.user.profile?.name}
                  </span>
                </h3>
                
              </div>
              

              <div style={{ paddingLeft: '2.25rem', paddingRight: '2.25rem' }}>
                
                {me.me.id === id && <div><Link className='btn btn-sm btn-primary' to={'/edit'}>Editar perfil</Link><br/><br/></div>}
                {me.me.id === id && <div><Link className='btn btn-sm btn-primary' to={'/campaigns'}>Criar campanhas</Link><br/><br/></div>}
                {users.user.profile?.cityParent?.name} {users.user.profile?.stateParent?.state}
                <br/>
                Tipo sanguíneo: {bloodtypetext(users.user.profile?.bloodtype!)}
                <br/>
                Pode receber doação de: {recebeDe(users.user.profile?.bloodtype!)}
                <br/>
                Pode doar para: {doaPara(users.user.profile?.bloodtype!)}
                <br/>
                {/* Campanha criada em: {created_at_text.format('DD/MM/YYYY HH:mm')} */}
                <br/><br/>
              </div>
            </div>
          </div>

          <div className='col-xxl-9'>

          <div className={`card card-xxl-stretch mb-5 mb-xxl-6`}>
            {/* begin::Header */}
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>
                  Campanhas criadas
                </span>
                <span className='text-muted mt-1 fw-bold fs-7'>
                  Clique no link para ser redirecionado
                </span>
              </h3>
            </div>
            {/* end::Header */}
            {/* begin::Body */}
            <div className='card-body py-3'>
              <div className='tab-content'>
                {/* begin::Tap pane */}
                <div className='tab-pane fade show active' id='kt_table_widget_5_tab_1'>
                  {/* begin::Table container */}
                  <div className='table-responsive'>
                    {/* begin::Table */}
                    <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                      {/* begin::Table head */}
                      <thead>
                        <tr className='border-0'>
                          <th className='p-0 w-150px'></th>
                          <th className='p-0 min-w-150px'></th>
                        </tr>
                      </thead>
                      {/* end::Table head */}
                      {/* begin::Table body */}
                      <tbody>
                        {campaigns.mycampaigns?.map((component: any, index) => {
                          return (
                            <tr>
                              <td>
                              <Link
                                  className='fs-6 text-gray-800 text-hover-primary fw-bolder'
                                  to={
                                    '/campanha/' +
                                    component.id
                                  }
                                  style={{display: 'flex'}}
                                >
                                <img src={'../../files/' + component.image} style={{width: '100%'}} 
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src="https://salvemaisum.com.br/media/guestuser.jpg";
                                  }} />
                                </Link>
                              </td>
                              <td>
                                <Link
                                  className='fs-6 text-gray-800 text-hover-primary fw-bolder'
                                  to={
                                    '/campanha/' +
                                    component.id
                                  }
                                  style={{display: 'flex'}}
                                >
                                  {component.name}
                                </Link>
                                <span className='text-muted fw-bold d-block'>{bloodtypetext(component.bloodtype)}</span>
                                <span className='text-muted fw-bold d-block'>Recebe sangue: {recebeDe(component.bloodtype)}</span>
                                <span className='text-muted fw-bold d-block'>{component.bcname}</span>
                                
                                {component.bcParent?.id?
                                  <span className='text-muted fw-bold d-block'>{component.bcParent?.name}/{component.bcParent?.cityParent?.name}/{component.bcParent?.stateParent?.state}</span>
                                  :<span className='text-muted fw-bold d-block'>{component.cityParent?.name}/{component.stateParent?.state}</span>
                                }
                              </td>
                              
                              {/* <td className='text-end text-muted fw-bold'>{component.parentName}</td> */}
                              {/* component.search.map((component,index) => 
                                  <div key={index}>
                                    {component.parentName} {component.name} {component.id} {component.parentId} {component.firstId} {component.value_extra}
                                  </div>
                              ) */}
                            </tr>
                          )
                        })}
                      </tbody>
                      {/* end::Table body */}
                    </table>
                  </div>
                  {/* end::Table */}
                </div>
                {/* end::Tap pane */}
              </div>
            </div>
            {/* end::Body */}
          </div>


        </div>
      </div>
      




      {/* end::Row */}
    </>
  )
}

const ViewProfile: FC<React.PropsWithChildren<unknown>> = () => {
  const intl = useIntl()
  const me = useSelector((state: ApplicationState) => state.me)
  const users = useSelector((state: ApplicationState) => state.users)
  //const component = useSelector((state: ApplicationState) => state.component)
  const campaign = useSelector((state: ApplicationState) => state.campaign)
  const dispatch = useDispatch()
  let {id} = useParams<ParamTypes>()

  console.log("ME AQUI", me)
  console.log("USERS", users)
  

  useEffect(() => {
    // console.log("############ Loading component hey...", { module_id, class_id, me })
      // console.log("ID", id)
      // console.log("camp id", campaign.campaign.id)
      // console.log("dispatch?", +campaign.campaign.id! !== +id!)
      
      //if(+campaign.campaign.id! !== +id!)
      dispatch(loadUserRequest(id!))
      dispatch(loadMyCampaignsRequest(+id!))
    
  }, [me.me])
  console.log("USER", users)
  document.title = 'Perfil '+ users.user?.profile?.name + ' | Salve mais um';
  if(users.loadingUser) return <Loading />

  return (
    <>
      {/* <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.SALVEMAISUM'})}</PageTitle> */}
      <PageTitle breadcrumbs={[]}>{users.user.profile?.name}</PageTitle>
      <ViewProfilePage users={users} campaigns={campaign} id={+id!} />
    </>
  )
}
export {ViewProfile}
