import React, {FC, useEffect} from 'react'
import {PageTitle} from '../../../design/layout/core'
// import {useIntl} from 'react-intl'
import {useParams} from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'
import {ApplicationState} from '../../../../store'
import {loadComponentRequest} from '../../../../store/ducks/component/actions'
import {ComponentState} from '../../../../store/ducks/component/types'

import {ManageItemWidget} from './ManageItemWidget'
import Loading from '../../../design/loading'
import { loadCampaignRequest, loadCampaignsRequest, loadMyCampaignsRequest } from '../../../../store/ducks/campaign/actions'
import { CampaignState } from '../../../../store/ducks/campaign/types'

type ParamTypes = {
  id: string
}

type Props = {
  campaigns: CampaignState
}

const ManagePage: React.FC<React.PropsWithChildren<Props>> = ({campaigns}) => (
  <>
    {/* begin::Row */}
    <div className='row g-5 gx-xxl-12'>
      <div className='col-xxl-12'>
        <ManageItemWidget campaigns={campaigns} className='card-xxl-stretch mb-5 mb-xxl-7' />
      </div>
    </div>
    {/* end::Row */}
  </>
)

const ManageCampaign: FC<React.PropsWithChildren<unknown>> = () => {
  // const intl = useIntl()

  const dispatch = useDispatch()
  const campaign = useSelector((state: ApplicationState) => state.campaign)
  const me = useSelector((state: ApplicationState) => state.me)
  //let {id} = useParams<ParamTypes>()
  console.log("ME", me.me.id)

  useEffect(() => {
    dispatch(loadMyCampaignsRequest(me.me.id!)) //Puxa componentes com seus filhos primários
  }, [])
  document.title = 'Minhas campanhas | Salve mais um';

  console.log("MY CAMPAIGNS", campaign)

  if (campaign.loadingMycampaigns) return <Loading />

  return (
    <>
      {/* <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.MODULES'})} </PageTitle> */}
      <PageTitle breadcrumbs={[]}>Minhas campanhas</PageTitle>
      <ManagePage campaigns={campaign} />
    </>
  )
}
export default ManageCampaign
