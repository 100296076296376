/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import { useDispatch } from 'react-redux'


export function Thankyou() {
  return (

      <div className='mb-10 text-center'>
        {/* begin::Title */}
        <h1 className='text-dark mb-3'>Falta 1 passo</h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className='text-gray-400 fw-bold fs-4'>
          Acabamos de enviar um e-mail. Abra seu e-mail e clique no link: "Confirme seu email"<br/>
          para liberação da sua conta.
          <br/><br/>
          <Link to='/auth/login' className='link-primary fw-bolder' style={{marginLeft: '5px'}}>
            Fazer login
          </Link>
        </div>
        {/* end::Link */}
      </div>
      
  )
}
