/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../../../store';
// import {KTSVG} from '../../../helpers'
// import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import { Link } from 'react-router-dom';
// import { useSelector, useDispatch } from 'react-redux';
// import { ApplicationState } from '../../../../store'

// type Props = {
//   changeMenu: React.Dispatch<React.SetStateAction<string>>
// };

export function AsideMenuMain() {
  const intl = useIntl()
  const me = useSelector((state: ApplicationState) => state.me);

  return (
    <>
      
      <AsideMenuItem
        to='/rede'
        icon='/media/icons/duotune/general/gen025.svg'
        fontIcon='bi-app-indicator'
        title={intl.formatMessage({id: 'MENU.SALVEMAISUM'})}
      />
      {!me.logged && <AsideMenuItem
        to='/auth/login'
        icon='/media/icons/duotune/communication/com006.svg'
        fontIcon='bi-app-indicator'
        title={intl.formatMessage({id: 'MENU.CREATECAMPAIGN'})}
      />}
      <AsideMenuItem
        to='/search'
        icon='/media/icons/duotune/general/gen004.svg'
        fontIcon='bi-app-indicator'
        title={intl.formatMessage({id: 'MENU.SEARCH'})}
      />
      {/* <AsideMenuItem
        to='/create'
        icon='/media/icons/duotune/general/gen025.svg'
        fontIcon='bi-app-indicator'
        title={intl.formatMessage({id: 'MENU.CREATECAMPAIGN'})}
      /> */}
      {me.logged
      &&<AsideMenuItem
        to='/campaigns'
        icon='/media/icons/duotune/abstract/abs011.svg'
        fontIcon='bi-app-indicator'
        title={intl.formatMessage({id: 'MENU.MYCAMPAIGNS'})}
      />}
      {me.logged
      &&<AsideMenuItem
        to={'/profile/'+me.me.id}
        icon='/media/icons/duotune/communication/com006.svg'
        fontIcon='bi-app-indicator'
        title={intl.formatMessage({id: 'MENU.MYPROFILE'})}
      />}
      {/*me.logged
      &&<AsideMenuItem
        to='/edit'
        icon='/media/icons/duotune/art/art003.svg'
        fontIcon='bi-app-indicator'
        title={intl.formatMessage({id: 'MENU.EDITPROFILE'})}
      />*/}
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Salve Mais Um</span>
        </div>
      </div>
      <AsideMenuItem
        to='/hemocentros'
        icon='/media/icons/duotune/medicine/med001.svg'
        fontIcon='bi-app-indicator'
        title={intl.formatMessage({id: 'MENU.BLOODCENTERS'})}
      />
      
      <AsideMenuItem
        to='/palestras'
        icon='/media/icons/duotune/abstract/abs020.svg'
        fontIcon='bi-app-indicator'
        title={intl.formatMessage({id: 'MENU.SPEECHES'})}
      />
      <AsideMenuItem
        to='/namidia'
        icon='/media/icons/duotune/social/soc007.svg'
        fontIcon='bi-app-indicator'
        title={intl.formatMessage({id: 'MENU.MEDIA'})}
      />
      <AsideMenuItem
        to='/redessociais'
        icon='/media/icons/duotune/general/gen018.svg'
        fontIcon='bi-app-indicator'
        title={intl.formatMessage({id: 'MENU.SOCIALMEDIA'})}
      />
      

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Links</span>
        </div>
      </div>
      
      <AsideMenuItem
        to='/initiative'
        icon='/media/icons/duotune/abstract/abs007.svg'
        fontIcon='bi-app-indicator'
        title={intl.formatMessage({id: 'MENU.INITIATIVE'})}
      />
      <AsideMenuItem
        to='/howitworks'
        icon='/media/icons/duotune/abstract/abs024.svg'
        fontIcon='bi-app-indicator'
        title={intl.formatMessage({id: 'MENU.HOWWORKS'})}
      />
      <AsideMenuItem
        to='/howtodonate'
        icon='/media/icons/duotune/general/gen029.svg'
        fontIcon='bi-app-indicator'
        title={intl.formatMessage({id: 'MENU.HOWDONATE'})}
      />
      <AsideMenuItem
        to='/whoweare'
        icon='/media/icons/duotune/abstract/abs005.svg'
        fontIcon='bi-app-indicator'
        title={intl.formatMessage({id: 'MENU.WHOWEARE'})}
      />
      <AsideMenuItem
        to='/contact'
        icon='/media/icons/duotune/communication/com002.svg'
        fontIcon='bi-app-indicator'
        title={intl.formatMessage({id: 'MENU.CONTACT'})}
      />
      <AsideMenuItem
        to='/doar'
        icon='/media/icons/duotune/finance/fin006.svg'
        fontIcon='bi-app-indicator'
        title={intl.formatMessage({id: 'MENU.DONATE'})}
      />

      <div className='pt-20 text-center'>
        <a href="https://carlosdj.com.br" className='text-dark' target='_blank'>
          Versão 2.0 <br/>
          salvemaisum.com.br<br/>
          Powered by <br/><span style={{ color: 'red', fontWeight: 'bold'}}>CARLOSDJ.COM.BR</span>
        </a>
      </div>
      
      
      {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Links</span>
        </div>
      </div>
      <AsideMenuItem
        to='/annotation'
        icon='/media/icons/duotune/general/gen055.svg'
        fontIcon='bi-app-indicator'
        title={intl.formatMessage({id: 'MENU.MYANNOTATIONS'})}
      />
      <AsideMenuItem
        to='/agenda/1318'
        icon='/media/icons/duotune/general/gen014.svg'
        fontIcon='bi-app-indicator'
        title={intl.formatMessage({id: 'MENU.AGENDA'})}
      /> */}
      {/* <AsideMenuItem
        to='/modules/808'
        icon='/media/icons/duotune/general/gen029.svg'
        fontIcon='bi-app-indicator'
        title={intl.formatMessage({id: 'MENU.REPLAY'})}
      /> */}

      {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Suporte</span>
        </div>
      </div>
      <AsideMenuItem
        to='/mysupport'
        icon='/media/icons/duotune/art/art006.svg'
        fontIcon='bi-app-indicator'
        title={intl.formatMessage({id: 'MENU.SUPPORT'})}
      /> */}
    </>
  )
}
