import {combineReducers} from 'redux'
// import repositories from './repositories';
import users from './users'
import me from './me'
import component from './component'
import extras from './extras'
import campaign from './campaign'
import state from './state'
import city from './city'
import contact from './contact'
import bloodcenter from './bloodcenter'

export default combineReducers({

  users,
  me,
  component,
  extras,
  campaign,
  state,
  city,
  contact,
  bloodcenter,

})
