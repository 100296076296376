/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {
  KTSVG,
  // toAbsoluteUrl
} from '../../design/helpers'
// import { ComponentState } from '../../../../store/ducks/component/types'
import {Link} from 'react-router-dom'
import {useIntl} from 'react-intl'
import { ComponentState } from '../../../store/ducks/component/types'
import { Campaign, CampaignState } from '../../../store/ducks/campaign/types'
const MOMENT = require('moment')
require("moment-duration-format");
// const MOMENT= require( 'moment' );

type Props = {
  className: string
  campaign: CampaignState
}

// let today = MOMENT().format( 'YYYY-MM-DD HH:mm:ss.000' );
// const add = (accumulator: number, a: number) => {
//   return accumulator + a;
// }

const doaPara = (bloodtype: string) => {
  switch (bloodtype) {
    case '1':
      return "A+, A-, B+, B-, AB+, AB-, O+, O-" //O-
    case '2':
      return "A+, B+, AB+, AB-" //O+
    case '3':
      return "A+, A-, AB+, AB-" //A-
    case '4':
      return "A+, AB+" //A+
    case '5':
      return "B+, B-, AB+, AB-" //B-
    case '6':
      return "B+, AB+" //B+
    case '7':
      return "AB+, AB-" //AB-
    case '8':
      return "AB+" //AB+
    default:
      return "Invalido"
  }
}

const recebeDe = (bloodtype: string) => {
  switch (bloodtype) {
    case '1':
      return "O-" //O-
    case '2':
      return "O+, O-" //O+
    case '3':
      return "A-, O-" //A-
    case '4':
      return "A+, A-, O+, O-" //A+
    case '5':
      return "B-, O-" //B-
    case '6':
      return "B+, B-, O+, O-" //B+
    case '7':
      return "A-, B-, AB-, O-" //AB-
    case '8':
      return "A+, A-, B+, B-, AB+, AB-, O+, O-" //AB+
    default:
      return "Invalido"
  }
}

const bloodtypetext = (bloodtype: string) => {
  switch (bloodtype) {
    case '1':
      return "O-"
    case '2':
      return "O+"
    case '3':
      return "A-"
    case '4':
      return "A+"
    case '5':
      return "B-"
    case '6':
      return "B+"
    case '7':
      return "AB-"
    case '8':
      return "AB+"
    default:
      return "Invalido"
  }
}

const SearchWidget: React.FC<React.PropsWithChildren<Props>> = ({className, campaign}) => {
  // console.log("MODULOS", comp)
  const intl = useIntl()
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>
            {intl.formatMessage({id: 'MENU.SEARCH'})}
          </span>
          <span className='text-muted mt-1 fw-bold fs-7'>
            Clique no link para ser redirecionado
          </span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        <div className='tab-content'>
          {/* begin::Tap pane */}
          <div className='tab-pane fade show active' id='kt_table_widget_5_tab_1'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='border-0'>
                    <th className='p-0 w-150px'></th>
                    <th className='p-0 min-w-150px'></th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {campaign.search?.map((component: Campaign, index) => {
                    return (
                      <tr>
                        <td>
                        <Link
                            className='fs-6 text-gray-800 text-hover-primary fw-bolder'
                            to={
                              '/campanha/' +
                              component.id
                            }
                            style={{display: 'flex'}}
                          >
                          <img 
                            src={'../../files/' + component.image} 
                            style={{width: '100%'}} 
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src="https://salvemaisum.com.br/media/guestuser.jpg";
                            }}
                          />
                          </Link>
                        </td>
                        <td>
                          <Link
                            className='fs-6 text-gray-800 text-hover-primary fw-bolder'
                            to={
                              '/campanha/' +
                              component.id
                            }
                            style={{display: 'flex'}}
                          >
                            {component.name}
                          </Link>
                          <span className='text-muted fw-bold d-block'>Tipo sanguíneo: {bloodtypetext(component.bloodtype!)}</span>
                          <span className='text-muted fw-bold d-block'>Precisa de: {recebeDe(component.bloodtype!)}</span>
                          <span className='text-muted fw-bold d-block'>{component.bcname}</span>
                          {component.bcParent?.id?
                            <span className='text-muted fw-bold d-block'>{component.bcParent?.name}/{component.bcParent?.cityParent?.name}/{component.bcParent?.stateParent?.state}</span>
                            :<span className='text-muted fw-bold d-block'>{component.cityParent?.name}/{component.stateParent?.state}</span>
                          }
                        </td>
                        
                        {/* <td className='text-end text-muted fw-bold'>{component.parentName}</td> */}
                        {/* component.search.map((component,index) => 
                            <div key={index}>
                              {component.parentName} {component.name} {component.id} {component.parentId} {component.firstId} {component.value_extra}
                            </div>
                        ) */}
                      </tr>
                    )
                  })}
                </tbody>
                {/* end::Table body */}
              </table>
            </div>
            {/* end::Table */}
          </div>
          {/* end::Tap pane */}
        </div>
      </div>
      {/* end::Body */}
    </div>
  )
}

export {SearchWidget}
