import {action} from 'typesafe-actions'
import {Campaign, CampaignTypes} from './types'
import {Extras} from '../extras/types'

//Load
export const loadCampaignsRequest = (page: number, take: number) =>
  action(CampaignTypes.LOAD_CAMPAIGNS_REQUEST, {page, take})
export const loadCampaignsSuccess = (data: Campaign) =>
  action(CampaignTypes.LOAD_CAMPAIGNS_SUCCESS, data)
export const loadCampaignsFailure = () => action(CampaignTypes.LOAD_CAMPAIGNS_FAILURE)

export const loadCampaignsCompatibilityRequest = (list: number[]) =>
  action(CampaignTypes.LOAD_CAMPAIGNSCOMPATIBILITY_REQUEST, list)
export const loadCampaignsCompatibilitySuccess = (list: number[], count: number) =>
  action(CampaignTypes.LOAD_CAMPAIGNSCOMPATIBILITY_SUCCESS, {list, count})
export const loadCampaignsCompatibilityFailure = () => action(CampaignTypes.LOAD_CAMPAIGNSCOMPATIBILITY_FAILURE)

export const bloodSelectedRequest = (blood: string) =>
  action(CampaignTypes.BLOOD_SELECTED, blood)


export const loadMyCampaignsRequest = (id: number) =>
  action(CampaignTypes.LOAD_MYCAMPAIGNS_REQUEST, {id})
export const loadMyCampaignsSuccess = (data: Campaign) =>
  action(CampaignTypes.LOAD_MYCAMPAIGNS_SUCCESS, data)
export const loadMyCampaignsFailure = () => action(CampaignTypes.LOAD_MYCAMPAIGNS_FAILURE)

export const searchCampaignsRequest = (text: string) =>
  action(CampaignTypes.SEARCH_CAMPAIGN_REQUEST, {text})
export const searchCampaignsSuccess = (data: Campaign) =>
  action(CampaignTypes.SEARCH_CAMPAIGN_SUCCESS, data)
export const searchCampaignsFailure = () => action(CampaignTypes.SEARCH_CAMPAIGN_FAILURE)

export const loadCampaignRequest = (id: number) =>
  action(CampaignTypes.LOAD_CAMPAIGN_REQUEST, {id})
export const loadCampaignSuccess = (data: Campaign) =>
  action(CampaignTypes.LOAD_CAMPAIGN_SUCCESS, data)
export const loadCampaignFailure = () => action(CampaignTypes.LOAD_CAMPAIGN_FAILURE)

//Create Campaign
export const createCampaignRequest = (newCampaign: Campaign) =>
  action(CampaignTypes.CREATE_CAMPAIGN_REQUEST, newCampaign)
export const createCampaignSuccess = (data: Campaign) =>
  action(CampaignTypes.CREATE_CAMPAIGN_SUCCESS, data)
export const createCampaignFailure = (err: any[]) =>
  action(CampaignTypes.CREATE_CAMPAIGN_FAILURE, err)

//Update Campaign
export const updateCampaignRequest = (CampaignToUpdate: Campaign) =>
  action(CampaignTypes.UPDATE_CAMPAIGN_REQUEST, CampaignToUpdate)
export const updateCampaignSuccess = (data: Campaign) =>
  action(CampaignTypes.UPDATE_CAMPAIGN_SUCCESS, data)
export const updateCampaignFailure = () => action(CampaignTypes.UPDATE_CAMPAIGN_FAILURE)

//Delete Campaign
export const deleteCampaignRequest = (id: number) =>
  action(CampaignTypes.DELETE_CAMPAIGN_REQUEST, id)
export const deleteCampaignSuccess = (id: number) =>
  action(CampaignTypes.DELETE_CAMPAIGN_SUCCESS, id)
export const deleteCampaignFailure = () => action(CampaignTypes.DELETE_CAMPAIGN_FAILURE)

