/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {Link, useNavigate} from 'react-router-dom'
import {loginUserRequest} from '../../../../store/ducks/me/actions'
import {ApplicationState} from '../../../../store'
import {Alert} from 'react-bootstrap-v5'
import SocialButton from './SocialButton'

export function Login() {
  const [loading, setLoading] = useState(false)
  const [login, setLogin] = useState('')
  const [pass, setPass] = useState('')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const me = useSelector((state: ApplicationState) => state.me);

  useEffect(() => {
    // document.body.classList.add('bg-white')
    if (me.logged) {
      //navigate("/")
      navigate("/profile/"+me.me.id)
    }

    return () => {
      // document.body.classList.remove('bg-white')
    }
  }, [me])
  //Cookies:
  // const [cookies, setCookie] = useCookies(['user']);
  // const cookieUser:User = cookies.user;


  console.log('me login', me)
  // console.log("Cookie user", cookieUser)
  // if(me.error){
  //   setLoading(false);
  // }
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    // console.log("eai?")
    event.preventDefault()
    if (login && pass) {
      setLoading(true)
      me.error = false
      console.log('System is trying to login user...')
      dispatch(loginUserRequest({email: login, password: pass}))
      //setLoading(false)
    } else {
      //errMsg('Dados inválidos, preencha novamente');
      console.log('Erro!')
      //setLoading(false)
    }
  }

  const handleSocialLogin = (user:any) => {
    console.log(user);
  };
  
  const handleSocialLoginFailure = (err:any) => {
    console.error(err);
  };

  return (
    <form onSubmit={handleSubmit}>
      {me.error && <Alert variant='danger'>{me.message}</Alert>}
      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <h1 className='text-dark mb-3'>Salve Mais Um</h1>
        <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
        <input
          type='text'
          className='form-control form-control-lg form-control-solid'
          style={{fontSize: 13}}
          id='login'
          name='email'
          placeholder='Digite seu e-mail'
          value={login}
          onChange={(e) => setLogin(e.target.value)}
        />
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            {/* begin::Label */}
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Senha</label>
            {/* end::Label */}
            {/* begin::Link */}

            {/* end::Link */}
          </div>
        </div>
        <input
          type='password'
          className='form-control form-control-lg form-control-solid'
          style={{fontSize: 13}}
          id='senha'
          name='password'
          aria-describedby='emailHelp'
          placeholder='Digite sua senha'
          value={pass}
          onChange={(e) => setPass(e.target.value)}
        />
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-2'
          // disabled={formik.isSubmitting || !formik.isValid}
        >
          {(!loading || me.error) && <span className='indicator-label'>Entrar</span>}
          {loading && !me.error && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Conectando...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}
      <Link
        to='/auth/forgot-password'
        className='link-primary fs-6 fw-bolder text-dark '
        // style={{marginLeft: '5px'}}
      >
        Esqueceu a senha?
      </Link>
      <br/><br/>
      
      <div className='d-flex align-items-center mb-10'>
        <div className='border-bottom border-gray-300 mw-50 w-100'></div>
        <span className='fw-bold text-gray-400 fs-7 mx-2'>OU</span>
        <div className='border-bottom border-gray-300 mw-50 w-100'></div>
      </div>
      <div className='text-center'>
        
        <Link
        to='/auth/registration'
        className='link-primary fs-6 fw-bolder'
        // style={{marginLeft: '5px'}}
      >
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-warning w-100 mb-2 text-dark'
          // disabled={formik.isSubmitting || !formik.isValid}
        >
          <span className='indicator-label'>Cadastre-se gratuitamente</span>
          
        </button>
        </Link>
      </div>

      <br/><br/>
      {/* <SocialButton
        provider="facebook"
        appId="YOUR_APP_ID"
        onLoginSuccess={handleSocialLogin}
        onLoginFailure={handleSocialLoginFailure}
      >
        Login with Facebook
      </SocialButton> */}
      
    </form>
  )
}
