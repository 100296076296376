import React, {FC, useEffect, useRef, useState} from 'react'
import {PageTitle} from '../../../design/layout/core'
import {useIntl} from 'react-intl'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../../../../store'
import { useDispatch } from 'react-redux'
import { loadCampaignRequest, loadMyCampaignsRequest } from '../../../../store/ducks/campaign/actions'
import { useNavigate, useParams } from 'react-router-dom'
import Loading from '../../../design/loading'
import { CampaignState } from '../../../../store/ducks/campaign/types'
import { loadUserRequest } from '../../../../store/ducks/users/actions'
import { loadMeRequest, updateMeRequest } from '../../../../store/ducks/me/actions'
import { UsersState } from '../../../../store/ducks/users/types'
import { Link } from 'react-router-dom'
import { Button, Form } from 'react-bootstrap-v5'
import { loadCityRequest } from '../../../../store/ducks/city/actions'
import { User } from '../../../../store/ducks/me/types'
import { useCookies } from 'react-cookie'
import api from '../../../../services/api'
import { loadStateRequest } from '../../../../store/ducks/state/actions'
import { useDebounceEffect } from '../../campaign/manage/useDebounceEffect'
import { canvasPreview } from '../../campaign/manage/canvasPreview'
import FileResizer from 'react-image-file-resizer'
import ReactCrop, { centerCrop, Crop, makeAspectCrop, PixelCrop } from 'react-image-crop'
const MOMENT = require('moment')
type ParamTypes = {
  id: string
}

type Props = {
  users: UsersState
  campaigns: CampaignState
}
// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number,
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  )
}
const EditProfilePage: FC<React.PropsWithChildren<Props>> = ({users, campaigns}) => {
  // let created_at_text = MOMENT(Number(users.user.created_at!) * 1000) //.format('DD/MM/YYYY HH:mm')
  // const title = `${document.title} | Salve Mais Um`;
  const [imgSrc, setImgSrc] = useState('')
  const [croppedImage, setCroppedImage] = useState<any>('')
  const previewCanvasRef = useRef<HTMLCanvasElement>(null)
  const [crop, setCrop] = useState<Crop>()
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
  const [scale, setScale] = useState(1)
  const [rotate, setRotate] = useState(0)
  const [aspect, setAspect] = useState<number | undefined>(1 / 1) //16 / 9
  const imgRef = useRef<HTMLImageElement>(null)

  function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined) // Makes crop preview update between images.
      const reader = new FileReader()
      reader.addEventListener('load', () =>
        setImgSrc(reader.result?.toString() || ''),
      )
      reader.readAsDataURL(e.target.files[0])
      setSelectedFile(e.target.files[0])
      
      //setIsSelected(true)
    } else {
      //setSelectedFile(e.target.files[0])
      setImgSrc('')
    }
  }
  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget
      setCrop(centerAspectCrop(width, height, aspect))
      //console.log("ei?")
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        //console.log("hum?")
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate,
        ).then(() => {
          //console.log("FOI..?", previewCanvasRef)

          previewCanvasRef.current?.toBlob((file: any) => {
            console.log("FILE", file)
            try {
              FileResizer.imageFileResizer(
                file,
                800,
                800,
                "JPEG",
                100,
                0,
                (uri) => {
                  console.log("Nova imagem:", uri);
                  setCroppedImage(uri)
                },
                "blob"
              );

              
            } catch (error) {
              console.log("error", error)
            }
            

            // let url = URL.createObjectURL(file);
            // console.log("URL", url)
          }, "image/jpeg");

        })
      }
    },
    100,
    [completedCrop, scale, rotate],
  )
  const [validated, setValidated] = useState(false)
  const [name, setName] = useState('')
  const [bloodtype, setBloodtype] = useState('')
  const [image, setImage] = useState('')
  const [candonate, setCandonate] = useState('')
  const [userCity, setUserCity] = useState('')
  const [userState, setUserState] = useState('')
  const [selectedFile, setSelectedFile] = useState<any>()
  const [isSelected, setIsSelected] = useState(false)
  const [preview, setPreview] = useState<any>()
  const dispatch = useDispatch()
  //Cookies:
  const [cookies, setCookie] = useCookies(['user'])
  const cookieUser: User = cookies.user
  const navigate = useNavigate()
  const me = useSelector((state: ApplicationState) => state.me)
  const state = useSelector((state: ApplicationState) => state.state)
  const city = useSelector((state: ApplicationState) => state.city)
  
  useEffect(() => {
    setName(users.user.profile?.name!)
    setBloodtype(users.user.profile?.bloodtype!)
    setImage(users.user.profile?.image!)
    setUserCity(''+users.user.profile?.cityParent?.id!)
    setUserState(''+users.user.profile?.stateParent?.id!)
    setCandonate(users.user.profile?.image!)
    // setIsSelected(true)
    // setSelectedFile(child.image!)
    console.log("Setando cookies")
    var date = new Date()
    date.setTime(date.getTime() + 1 * 24 * 60 * 60 * 1000) //Days *
    setCookie('user', me.me, {
      path: '/',
      expires: date, //maxAge?
    })
    console.log("cookies novo", cookieUser)

    console.log("CITY",city)
    console.log("STATE",state)
  
  }, [users.user, me.me])
  
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    //console.log('submit', campaign.data.id)
    

    
    const form = event.currentTarget
    event.preventDefault()
    if (form.checkValidity() === false) {
      event.stopPropagation()
    }
    setValidated(true)
    if (name && bloodtype) {
      if (selectedFile) {
        //Se selecionou arquivo novo
        console.log("Selecionado novo arquivo")
        const formdata = new FormData()
        formdata.append('file', croppedImage, 'salvemaisum.jpg') //selectedFile.name
        console.log('[formData]', formdata)
        console.log('selectedFile', selectedFile)
        api.post('/upload', formdata, {})
          .then((res:any) => {
            
            console.log("Selecionou arquivo")
            var data = new Date()
            const userToUpdate : User = {
              id: users.user.id,
              profile: {
                user_id: users.user.id,
                name,
                bloodtype,
                profileUserId: users.user.id!,
                image: res.data.filename,
                city: +userCity,
                state: +userState,
              },
    
              updated_at: (data.getTime() / 1000),
            }
            dispatch(updateMeRequest(userToUpdate))
            
          })
       } else {
        //Se não selecionou nenhuma foto nova:
        console.log("Nao selecionou nenhum arquivo")
        var data = new Date()
        const userToUpdate : User = {
          id: users.user.id,
          profile: {
            user_id: users.user.id,
            name,
            bloodtype,
            profileUserId: users.user.id!,
            city: +userCity,
            state: +userState,
          },

          updated_at: (data.getTime() / 1000),
        }

        console.log('------------------ USER TO UPDATE', userToUpdate)
        dispatch(updateMeRequest(userToUpdate))
        
        // history.push('/home');
        
    //     handleClose()
       }
       navigate('/profile/'+me.me.id)
     }

    
  }

  const changeHandler = (event: any) => {
    setSelectedFile(event.target.files[0])
    setIsSelected(true)
    const objectUrl = URL.createObjectURL(event.target.files[0])
    setPreview(objectUrl)
  }

  const setState = (id: string) => {
    setUserState(id)
    setUserCity('')
    dispatch(loadCityRequest(id))
  }

  const iconSize = 48;
  const intl = useIntl()
  return (
    <>
      {/* begin::Row */}
      
      <div className='row gy-5 g-xl-8'>
          <div className='col-xxl-12'>
          

          <div className={`card card-xxl-stretch mb-5 mb-xxl-6`}>
            {/* begin::Header */}
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>
                  Editar perfil
                </span>
                <span className='text-muted mt-1 fw-bold fs-7'>
                  Edite seu perfil
                </span>
              </h3>
            </div>
            {/* end::Header */}
            {/* begin::Body */}
            <div className='card-body py-3'>
              <div className='tab-content'>
                
              <Form validated={validated} onSubmit={handleSubmit}>
                <div className='row g-5 gx-xxl-12'>
                  <div className='col-xxl-9'>
                    <Form.Group controlId='fromName'>
                      <Form.Label>Nome completo</Form.Label>
                      <Form.Control
                        placeholder=''
                        required
                        value={name}
                        onChange={(e:any) => setName(e.target.value)}
                      />
                      <Form.Control.Feedback type='invalid'>Por favor informe o nome</Form.Control.Feedback>
                    </Form.Group>
                    <br />

                    <Form.Group controlId="formBasicSelect">
                      <Form.Label>Tipo Sanguineo</Form.Label>
                      <Form.Control
                        required
                        as="select"
                        value={bloodtype}
                        onChange={(e:any) => setBloodtype(e.target.value)}
                      >
                        <option value="" selected disabled hidden>Selecione um tipo sanguíneo</option>
                        <option value="1" selected={bloodtype === '1'?true:false}>O-</option>
                        <option value="2" selected={bloodtype === '2'?true:false}>O+</option>
                        <option value="3" selected={bloodtype === '3'?true:false}>A-</option>
                        <option value="4" selected={bloodtype === '4'?true:false}>A+</option>
                        <option value="5" selected={bloodtype === '5'?true:false}>B-</option>
                        <option value="6" selected={bloodtype === '6'?true:false}>B+</option>
                        <option value="7" selected={bloodtype === '7'?true:false}>AB-</option>
                        <option value="8" selected={bloodtype === '8'?true:false}>AB+</option>
                      </Form.Control>
                    </Form.Group>

                    
                    <br />

                    
                  

                    
                      <Form.Group controlId="formBasicSelect">
                      <Form.Label>Estado</Form.Label>

                      {state.loading?<Loading/>:
                        <Form.Control
                          as="select"
                          value={userState}
                          onChange={(e:any) => setState(e.target.value)}
                          required
                        >
                          <option value="" selected disabled hidden>Selecione</option>
                          {state.data.map((st:any) => {
                            return <option value={st.id} selected={+st.id === +userState}>{st.name}</option>
                          })}
                          </Form.Control>
                          }
                      </Form.Group>
                      <br/>
                      <Form.Group controlId="formBasicSelect">
                      <Form.Label>Cidade</Form.Label>
                      {city.loading?<Loading/>:
                        <Form.Control
                          as="select"
                          value={userCity}
                          onChange={(e:any) => setUserCity(e.target.value)}
                          required
                        >
                          <option value="" selected disabled hidden>Selecione</option>
                          {city.data.map((ct:any) => {
                            return <option value={ct.id} selected={+ct.id === +userCity}>{ct.name}</option>
                          })}
                          </Form.Control>
                        }
                      </Form.Group>
                      <br />
                      
                     
                    
                    </div>
                    <div className='col-xxl-3'>
                      <Form.Group>
                        <Form.Label>Selecione uma foto</Form.Label>
                        <Form.Control
                          name='image'
                          id='image'
                          type='file'
                          accept='image/*'
                          onChange={onSelectFile}
                        />
                        <Form.Control.Feedback type='invalid'>Selecione um arquivo</Form.Control.Feedback>
                      </Form.Group>
                      <br />
                      
                      <div>

                        <label htmlFor="image">
                        
                          <a style={{cursor: 'pointer'}}>
                            
                            {image && !imgSrc && <img 
                            src={ image?.includes('https://') ? image : '../../files/' + image}
                            style={{width: '100%'}}
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src="https://salvemaisum.com.br/media/guestuser.jpg";
                            }}
                            />}
                            {selectedFile &&  <img src={preview} style={{width: '50%'}} />}
                          </a>
                        </label> 
                      </div>


                            
                      <div className='row g-5 gx-xxl-12'>
                        <div className='col-6'>
                          {!!imgSrc && (
                            <div>
                              <div>Posicione a foto</div>
                              <ReactCrop
                                crop={crop}
                                onChange={(_, percentCrop) => setCrop(percentCrop)}
                                onComplete={(c) => setCompletedCrop(c)}
                                aspect={aspect}
                                maxHeight={350}
                                maxWidth={350}

                              >
                                <img
                                  ref={imgRef}
                                  alt="Crop me"
                                  src={imgSrc}
                                  style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                                  onLoad={onImageLoad}
                                />
                              </ReactCrop>
                            </div>
                          )}
                        </div>
                        <div className='col-6'>
                        <div>
                          {!!completedCrop && !!imgSrc && (
                            <div>
                              <div>Prévia</div>
                              <canvas
                                ref={previewCanvasRef}
                                style={{
                                  border: '1px solid black',
                                  objectFit: 'contain',
                                  width: completedCrop.width,
                                  height: completedCrop.height,
                                }}
                              />
                            </div>
                          )}
                        </div>
                        </div>
                  </div>

                      
                      <br/><br/>
                      


                    </div>
                  </div>
                  
                  



                  <Button size='sm' variant='primary' type='submit' className='float-right'>
                    Salvar
                  </Button>
                </Form>

                <br/>
                <br/>
              </div>
            </div>
            {/* end::Body */}
          </div>


        </div>
      </div>
      




      {/* end::Row */}
    </>
  )
}

const EditProfile: FC<React.PropsWithChildren<unknown>> = () => {
  const intl = useIntl()
  const me = useSelector((state: ApplicationState) => state.me)
  const users = useSelector((state: ApplicationState) => state.users)
  
  //const component = useSelector((state: ApplicationState) => state.component)
  const campaign = useSelector((state: ApplicationState) => state.campaign)
  const dispatch = useDispatch()
  let {id} = useParams<ParamTypes>()

  console.log("ME AQUI", me)
  console.log("USERS", users)
  

  useEffect(() => {
      dispatch(loadUserRequest(''+me.me.id!))  
      //Carrega estados e cidades desse estado
      dispatch(loadStateRequest()) //Puxa componentes com seus filhos primários
      dispatch(loadCityRequest(''+me.me.profile?.stateParent?.id!)) 
      console.log("CARREGANDO...", me.me.profile?.stateParent?.id!)
      console.log("Me", me)
      //console.log("Me", me)
  }, [])
  document.title = 'Editar '+ users.user?.profile?.name + ' | Salve mais um';
  console.log("USER", users)
  if(users.loadingUser) return <Loading />

  return (
    <>
      {/* <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.SALVEMAISUM'})}</PageTitle> */}
      <PageTitle breadcrumbs={[]}>{users.user.profile?.name}</PageTitle>
      <EditProfilePage users={users} campaigns={campaign} />
    </>
  )
}
export {EditProfile}
